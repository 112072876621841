import React from "react";
import { Link } from "react-router-dom";
function Nabar() {
  const addrss = "/#products";
  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-light bg-light static-top">
        <div className="container">
          <Link className="navbar-brand" to={"/"}>
            <img src={"/assets/img/pixium-logo.png"} alt="..." height="36" />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div
            className="collapse navbar-collapse "
            id="navbarSupportedContent"
          >
            <ul className="navbar-nav ms-auto text-uppercase">
              <li className="nav-item">
                <Link className="nav-link" to={"/"}>
                  Home
                </Link>
              </li>
              <li className="nav-item">
                <a className="nav-link" href={addrss}>
                  Products
                </a>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to={"/Contact"}>
                  Contact
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to={"/Contact"}>
                  Support
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to={"/Contact"}>
                  Where to Buy
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to={"/About"}>
                  About
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}
export default Nabar;
