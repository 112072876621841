import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";
import RelatedProducts from "../Components/RelatedProducts";
//Styling
import "react-fontawesome";
import "./Home.css";
import "../Components/responsive.css";
//SLick Slider
import Slider from "react-slick";
export default function M20() {
  const hero = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 1100,
    autoplaySpeed: 3000,
    cssEase: "linear",
    nextArrow: (
      <div>
        <div className="next-slick-arrow"> ⟩ </div>
      </div>
    ),
    prevArrow: (
      <div>
        <div className="prev-slick-arrow"> ⟨ </div>
      </div>
    ),
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
          dots: true,
          arrows: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          dots: true,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };
  // THumbnail Slider
  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);
  const [slider1, setSlider1] = useState(null);
  const [slider2, setSlider2] = useState(null);

  useEffect(() => {
    setNav1(slider1);
    setNav2(slider2);
  });
  return (
    <>
      <Navbar />
      <section className="conatiner text-center page-section mb-0 hero">
        <div className="contianer hero slider">
          <br />
          <Slider {...hero}>
          <div>
              <img
                src={"/assets/img/products/M20/flayers/M20-1.png"}
                alt="pixium"
                className="center img-fluid"
              />
            </div>
          <div>
              <img
                src={"/assets/img/products/M20/flayers/M20-2.png"}
                alt="pixium"
                className="center img-fluid"
              />
            </div>           
            <div>
              <img
                src={"/assets/img/products/M20/flayers/M20-3.png"}
                alt="pixium"
                className="center img-fluid"
              />
            </div>
          </Slider>
        </div>
      </section>
      <br />
      <section className="text-center">
        <h2 className="text-muted">PRXIUM M 20 </h2>

        <div className="container-xxl py-5">
          <div className="container-xxl py-5">
            <div className="container">
              <div className="row g-5">
                <div className="col-md-12">
                  <div className="h-100">
                    <img
                      className="card-img rounded-0  img-fluid"
                      src="/assets/img/products/Details/M20/1.jpg"
                    />
                    <img
                      className="card-img rounded-0  img-fluid"
                      src="/assets/img/products/Details/M20/2.jpg"
                    />
                    <img
                      className="card-img rounded-0  img-fluid"
                      src="/assets/img/products/Details/M20/3.jpg"
                    />
                    <img
                      className="card-img rounded-0  img-fluid"
                      src="/assets/img/products/Details/M20/4.jpg"
                    />
                    <img
                      className="card-img rounded-0  img-fluid"
                      src="/assets/img/products/Details/M20/5.jpg"
                    />
                    <img
                      className="card-img rounded-0  img-fluid"
                      src="/assets/img/products/Details/M20/7.jpg"
                    />
                    <img
                      className="card-img rounded-0  img-fluid"
                      src="/assets/img/products/Details/M20/8.jpg"
                    />
                    <img
                      className="card-img rounded-0  img-fluid"
                      src="/assets/img/products/Details/M20/9.jpg"
                    />
                    <p className="mb-4"> </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="container text-center ">
          <br />
          <Link to="/Contact" class="g-5 btn btn-outline-primary btn-lg">
            {" "}
            Where to buy
          </Link>
        </div>
      </section>
      <RelatedProducts />
      <Footer />
    </>
  );
}
