import { React, useEffect, useState } from "react";
function BackToTop() {
  const [isVisible, setIsVisible] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 100) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    });
  }, []);
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      scrollBehavior: 'smooth !important',
    });
  };
  return (
    <div>
      {isVisible && (
        <button
        className="bakctoTop"
          onClick={scrollToTop}
          style={{
            position: "fixed",
            bottom: "20px",
            left: "20px",
            backgroundColor: "white",
            padding: "10px",
            borderRadius: "5px",
            border: "none",
            scrollBehavior: 'smooth',
            cursor: "pointer",
          }}
        >
<i class="fa-solid fa-angles-up"></i> Go Up
        </button>
      )}
    </div>
  );
}

export default BackToTop;
