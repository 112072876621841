import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";
import RelatedProducts from "../Components/RelatedProducts";
//Styling
import "react-fontawesome";
import "./Home.css";
import "../Components/responsive.css";
//SLick Slider
import Slider from "react-slick";
export default function M30() {
  const hero = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 1100,
    autoplaySpeed: 3000,
    cssEase: "linear",
    nextArrow: (
      <div>
        <div className="next-slick-arrow"> ⟩ </div>
      </div>
    ),
    prevArrow: (
      <div>
        <div className="prev-slick-arrow"> ⟨ </div>
      </div>
    ),
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
          dots: false,
          arrows: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          dots: false,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };
  // THumbnail Slider
  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);
  const [slider1, setSlider1] = useState(null);
  const [slider2, setSlider2] = useState(null);

  useEffect(() => {
    setNav1(slider1);
    setNav2(slider2);
  });
  return (
    <>
      <Navbar />
        <div className="container-fluid">
          <img
          style={{height:"auto", width:"100%"}}
                src={"/assets/img/products/Details/M30/M30.jpg"}
                alt="pixium"
                className="center img-responsive"
              />
        </div>
      <br />
      <section class="text-center">
        <h2 class="text-muted">PRXIUM M 30 </h2>

        <div class="container-xxl py-5">
          <div class="container-xxl py-5">
            <div class="container">
              <div class="row g-5">
                <div class="col-md-12">
                  <div class="h-100">
                    <img
                      class="card-img rounded-0  img-fluid"
                      src="/assets/img/products/Details/M30/black.jpg"
                    />
                    <img
                      class="card-img rounded-0  img-fluid"
                      src="/assets/img/products/Details/M30/blue.jpg"
                    />
                    <img
                      class="card-img rounded-0  img-fluid"
                      src="/assets/img/products/Details/M30/green.jpg"
                    />
                    <img
                      class="card-img rounded-0  img-fluid"
                      src="/assets/img/products/Details/M30/M30.jpg"
                    />
                    <img
                      class="card-img rounded-0  img-fluid"
                      src="/assets/img/products/Details/M30/super slim.jpg"
                    />
                    <p class="mb-4"> </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="container text-center ">
          <br />
          <Link to="/Contact" class="g-5 btn btn-outline-primary btn-lg">
            {" "}
            Where to buy
          </Link>
        </div>
      </section>
      <RelatedProducts />
      <Footer />
    </>
  );
}
