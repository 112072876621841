import React, {useEffect} from "react";
import BackToTop from "../Components/BackToTop";
import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";
import "./Privacy.css";

import aos from "aos";
import "aos/dist/aos.css";

export default function About() {
  useEffect(()=>{
    aos.init({duration:1000}); 
  })
  return (
    <>
      <Navbar />
      <div>
        <header id="hero"></header>
        <section className="container Privacy">
          <h1 className="text-center">Our Privacy Policies</h1>
          <div className="row pt-5 pb-3" >
            <p data-aos="zoom-in">
              Pixium recognizes that privacy is of great importance to
              individuals everywhere—our customers, website visitors, product
              users… everyone. This is why the responsible use and protection of
              personal and other information under our care is a core Pixium
              value. To learn more about our privacy practices, please click any
              of the links below. If you have any further questions or concerns,
              please feel free to reach us at{" "}
              <a href="mailto:privacy@pixiumworld.com">
                privacy@pixiumworld.com
              </a>
              .
            </p>
            <p data-aos="zoom-in">
              The privacy statement was last updated on <span>03/03/2020</span>{" "}
              .
            </p>
            <p data-aos="zoom-in">
              We adhere to the GDPR as currently the most robust privacy
              legislation in the world and one of its main requirements for any
              business that falls under its jurisdiction according to
              GDPR-compliant Privacy Policy.
            </p>
            <p data-aos="zoom-in">
              <a href="https://www.privacypolicies.com/blog/gdpr-privacy-policy/">
                https://www.privacypolicies.com/blog/gdpr-privacy-policy/
              </a>
            </p>

            <ol >
              <li className="h3" data-aos="zoom-in">What this statement applies to:</li>
              <p data-aos="zoom-in">
                <span>About this statement</span>
              </p>
              <p data-aos="zoom-in">
                Your privacy is important to Pixium. This privacy statement
                applies to websites owned and operated by Pixium Group and its
                affiliated group companies (“Pixium”). This policy describes the
                information practices for Pixium websites (such as{" "}
                <span>www.pixiumworld.com</span> ), including what type of
                information is collected, how the information is used, with whom
                the information is shared, and how the information is protected.
              </p>
              <p data-aos="zoom-in">
                <span>What this statement does not apply to:</span>
              </p>
              <p data-aos="zoom-in">
                Pixium websites may contain links to other websites. Pixium is
                not responsible for the privacy practices or the content of
                those other websites. Please also note that this statement does
                not apply to information collected via Pixium’s products and
                software as well as via some other sources.
              </p>
              <p data-aos="zoom-in">
                <span>Your consent:</span>
              </p>
              <p data-aos="zoom-in">
                By using a Pixium website or otherwise providing (either
                directly to us or through third parties acting on our behalf)
                your personal information, you consent to and agree with the
                terms of this privacy policy (including the overseas transfer of
                your personal information in line with our comments below).
                Whenever you submit your information you consent to the
                collection, use and disclosure of that information in accordance
                with this privacy statement.
              </p>
              <li className="h3" data-aos="zoom-in">
                What information does Pixium collect through its websites?
              </li>
              <p data-aos="zoom-in">
                Pixium collects the following information from visitors to its
                websites:
              </p>
              <p data-aos="zoom-in">Information you provide to us voluntarily:</p>
              <p data-aos="zoom-in">
                In general, you can visit Pixium’s websites without telling us
                who you are and without giving any directly identifying personal
                information about yourself. There are times, however, when we
                (or suppliers working for us) may need information from you. You
                may choose to give us personal information in a variety of
                situations. For example, we may collect personal information
                about you when you place an order with Pixium, such as name,
                address, email address, phone numbers, payment card information,
                information used to personalize a Pixium product or software
                experience, social security number (or equivalent national
                identification number), date of birth and other information you
                choose to provide. Pixium uses this information to facilitate
                order processing and to enable us to contact you if a problem
                arises with your order. Additionally, if you purchase a product
                requiring service, your personal information may be used to
                obtain a credit report, if necessary. Please note that if you do
                not provide us with personal information where required, we may
                not be able to provide you with the products or services you
                have requested.
              </p>
              <p data-aos="zoom-in">
                We may ask you for and collect personal information at other
                times, including when you enter sweepstakes, a contest or other
                promotion; when you subscribe to receive Pixium marketing
                communications or newsletters; when you complete surveys; when
                you participate in a user forum or blog; or when you interact
                with Pixium at a trade show, conference or similar event. We use
                the information you provide to help us design and improve our
                website and our products, to customize your online experience,
                to provide advice and purchase recommendations, and for other
                purposes consistent with this policy.
              </p>
              <p data-aos="zoom-in">
                Pixium also conducts research on our users’ demographics (e.g.
                age, gender), interests, and behavior based on the information
                provided to us when making a purchase, during a promotion, from
                surveys and from our server log files. We do this to better
                understand and serve you. This research is compiled and analyzed
                on an aggregated basis so as to prevent identification of
                personal information relating to any one individual. Pixium may
                share this aggregated data with our suppliers.
              </p>
              <p data-aos="zoom-in">
                Finally, we may also ask for your personal information when you
                express an interest in employment opportunities at Pixium by
                submitting an application through our websites. The information
                we collect from you will be made apparent at the time you are
                submitting your application, and we will use this information
                only to evaluate your employment candidacy.
              </p>
              <p data-aos="zoom-in">Information we collect automatically:</p>
              <p data-aos="zoom-in">
                Our web server logs collect the domain names and certain related
                data of visitors to our web sites automatically (such as their
                IP address or device identifier). This information does not
                identify our visitors directly, and is used to measure number of
                visits, average time spent on a Pixium website, pages viewed and
                website usage information. We use this information to meet legal
                or regulatory requirements; to measure the use of our sites; to
                improve the content of our sites; and to provide tailored
                advertisements. This information is not used in a form that
                would enable direct identification of any of our visitors. We
                may collect this information using cookies, as explained below
                under the section “Does Pixium user cookies, web beacons,
                analytics and related technologies for online advertising and
                other purposes?”
              </p>
              <p data-aos="zoom-in">Information we collect from other sources:</p>
              <p data-aos="zoom-in">
                From time-to-time, we may supplement information you give us via
                a Pixium website with information from other sources, such as
                information validating your address or other available
                information about businesses. This is to help us maintain the
                accuracy of the information we collect and to help us provide a
                better service.
              </p>
              <li className="h3" data-aos="zoom-in">
                How does Pixium use and share the information it collects
                through its websites?
              </li>
              <p data-aos="zoom-in">
                The following sections describe in more detail how Pixium may
                use your personal information and with whom we may share it.
                Depending on the type of Pixium website you are visiting, one or
                more of the sections may apply. We may share your personal
                information with contracted suppliers who are providing services
                to Pixium. In some cases, Pixium uses suppliers to collect, use,
                analyze, and otherwise process information on its behalf. It is
                Pixium’s practice to require such suppliers to handle
                information in a manner consistent with our policies.
              </p>
              <p data-aos="zoom-in">
                <span>When You Request or Order Something:</span>
              </p>
              <p data-aos="zoom-in">
                If you request or order something from a Pixium website, such as
                a product or service, a callback, or specific marketing
                materials, we will use the information you provide to fulfill
                your request. To help us do this, we may share information with
                others, for instance, other parts of Pixium, Pixium’s service
                providers, vendors, suppliers, financial institutions, shipping
                companies, postal or government authorities (e.g., Customs
                authorities) involved in the fulfillment of your request. In
                connection with a transaction, we may also contact you as part
                of our customer satisfaction surveys or for market research
                purposes. To opt-out of surveys and/or other market research
                communications, see the section below headed “Marketing”.
              </p>
              <p data-aos="zoom-in">
                <span>Pixium ID and Product Registration:</span>
              </p>
              <p data-aos="zoom-in">
                When you create a Pixium ID or register your Pixium product, we
                will collect information, including personal information, about
                you and your Pixium device(s). In addition to your Pixium ID, we
                may ask you to provide your name, email address, language and
                region/country, device type/model, IP address, device serial
                number, date of device registration, and any activation data
                about your operating system.
              </p>
              <p data-aos="zoom-in">
                We use your Pixium ID to identify you when you use our online
                services, like our online store and customer support site.. If
                you wish to deactivate your Pixium ID, you may do so at any time
                by contacting{" "}
                <a href="mailto:privacy@pixiumworld.com">
                  privacy@pixiumworld.com
                </a>
                .
              </p>
              <p data-aos="zoom-in">
                <span>Marketing:</span>
              </p>
              <p data-aos="zoom-in">
                The information you provide on certain Pixium websites may also
                be used by Pixium and selected suppliers or partners for
                marketing purposes, such as when you subscribe to receive
                marketing communications or newsletters, or when you enter
                sweepstakes, a contest or promotion. Before this information is
                collected we may offer you the opportunity to choose whether or
                not to have your information used in this way. You may choose to
                stop receiving our marketing communications by following the
                unsubscribe instructions included in those communications,
                accessing the marketing preferences in your account settings
                page, or you can contact us at{" "}
                <a href="mailto:privacy@pixiumworld.com">
                  privacy@pixiumworld.com
                </a>
                .
              </p>
              <p data-aos="zoom-in">
                <span>Testimonials:</span>
              </p>
              <p data-aos="zoom-in">
                Pixium sometimes displays product reviews, testimonials and
                other endorsements of satisfied customers on our site. With your
                consent, we may post your comments along with your name. If you
                want to update or delete your comments, you can contact us at{" "}
                <a href="mailto:privacy@pixiumworld.com">
                  privacy@pixiumworld.com
                </a>
                .
              </p>
              <p data-aos="zoom-in">
                <span>Service Quality Monitoring:</span>
              </p>
              <p data-aos="zoom-in">
                Certain website activities may also involve you calling Pixium
                or Pixium, or its repair providers, calling you. Please note
                that, in some cases, we may record and retain such calls for
                staff training and quality assurance purposes. If you do not
                wish the call to be recorded, please notify the call taker.
              </p>
              <p data-aos="zoom-in">
                <span>Business Partner Relationships:</span>
              </p>
              <p data-aos="zoom-in">
                If you represent a Pixium business partner (i.e. a distributor
                or reseller of Pixium products), you may visit a Pixium website
                intended specifically for Pixium business partners. We may use
                personal information provided on that site to administer and
                develop our business relationship with you, and the company you
                represent.. For instance, this may involve using your
                information to send you details of Pixium business partner
                programs. It may also include sharing certain information with
                other business partners (subject to any confidentiality
                obligations that may exist), or Pixium customers or prospects.
                In connection with a particular transaction or program, we may
                also contact you as part of customer satisfaction surveys or for
                market research purposes.
              </p>
              <p data-aos="zoom-in">
                <span>Supplier Administration:</span>
              </p>
              <p data-aos="zoom-in">
                If you represent a Pixium supplier, you may visit a Pixium
                website intended specifically for use by Pixium suppliers. We
                may use the information provided on that site in connection with
                entering into or performing a transaction with you. For example,
                this may include sharing information with other parts of Pixium,
                Pixium’s business partners, customers, shipping companies,
                financial institutions, postal or government authorities
                involved in fulfillment of a transaction. It may also be used to
                administer and develop our relationship with you, the supplier
                you represent and/or other Pixium suppliers generally.
              </p>
              <p data-aos="zoom-in">
                <span>Disclosures Required by Law:</span>
              </p>
              <p data-aos="zoom-in">
                In certain circumstances, it is possible that personal
                information may be subject to judicial or other government
                subpoenas, warrants, or orders. Therefore, when we believe in
                good faith that the law requires it, we may disclose personal or
                other information to regulatory or government bodies, courts of
                law, tribunals, and law enforcement bodies to protect our
                rights, protect your safety or the safety of others, investigate
                fraud, or respond to a government request.
              </p>
              <p data-aos="zoom-in">
                <span>Blogs & Discussion Forms:</span>
              </p>
              <p data-aos="zoom-in">
                If you participate in a Pixium blog or discussion forum, you
                should be aware that the information you provide there may be
                made broadly available to others, potentially inside or outside
                Pixium, who have access to that blog, discussion forum, or chat
                room. Also, please recognize that individual blogs, forums, and
                chat rooms may be hosted by organizations other than Pixium
                and/or may have additional rules and conditions. Each
                participant’s opinion on a blog, forum or chat room is his or
                her own and should not be considered as reflecting the opinion
                of Pixium.
              </p>
              <p data-aos="zoom-in">
                <span>Social media features & widgets:</span>
              </p>
              <p data-aos="zoom-in">
                Some of our websites include social media features and links,
                such as a social bar with icons that link to the Pixium presence
                on that specific site (e.g., the Pixium page on Facebook, the
                Pixium feed on Twitter). These features may collect your IP
                address and information on which page you are visiting on our
                site, and may set a cookie to enable the feature to function
                properly. Social media features and widgets are either hosted by
                another company or are hosted directly on our site. Your
                interactions with these features are governed by the privacy
                policy of the company providing it.
              </p>
              <p data-aos="zoom-in">
                <span>Social media features & widgets:</span>
              </p>
              <p data-aos="zoom-in">
                Some of our websites include social media features and links,
                such as a social bar with icons that link to the Pixium presence
                on that specific site (e.g., the Pixium page on Facebook, the
                Pixium feed on Twitter). These features may collect your IP
                address and information on which page you are visiting on our
                site, and may set a cookie to enable the feature to function
                properly. Social media features and widgets are either hosted by
                another company or are hosted directly on our site. Your
                interactions with these features are governed by the privacy
                policy of the company providing it.
              </p>
              <p data-aos="zoom-in">
                <span>Single Sign On:</span>
              </p>
              <p data-aos="zoom-in">
                You can log in to certain areas of our websites using sign-in
                services, such as Facebook Connect or an Open ID provider. These
                services will authenticate your identity; provide you the option
                to share certain personal information (such as your name and
                email address) with us, and to pre-populate our sign up form.
                Services like Facebook Connect give you the option to post
                information about your activities on this website to your
                profile page to share with others within your network.
              </p>
              <p data-aos="zoom-in">
                <span>Frames & Powered-By Sites:</span>
              </p>
              <p data-aos="zoom-in">
                Some of our pages use framing techniques to serve content from
                suppliers or partners while preserving the look and feel of our
                site. In addition, certain Pixium-branded websites may be
                operated by suppliers or partners may be designated by a
                “powered-by” logo. Please be aware that such sites may be
                governed by the privacy practices and policies of our suppliers
                or partners and not necessarily those of Pixium or Pixium.org.
              </p>
              <p data-aos="zoom-in">
                <span>Mergers & Acquisitions:</span>
              </p>
              <p data-aos="zoom-in">
                Circumstances may arise when, whether for strategic or other
                business reasons, Pixium decides to sell, buy, merge, or
                otherwise reorganize businesses in some countries. You will be
                notified via email and/or a prominent notice on our website of
                any change in ownership or uses of your personal information, as
                well as any choices you may have regarding your personal
                information. Such a transaction may involve the disclosure of
                personal information to prospective or actual purchasers, or
                receiving it from sellers.
              </p>
              <p data-aos="zoom-in">
                <li className="h3" data-aos="zoom-in">Other Source</li>
              </p>
              <p data-aos="zoom-in">
                In addition to the above, there may circumstances which require
                us to disclose your personal information to parties in addition
                to those listed above, including, but not limited to, legal
                advisers or debt collection agencies.
              </p>
              <p data-aos="zoom-in">
                <li className="h3" data-aos="zoom-in">
                  Does Pixium transfer my personal information internationally?
                </li>
              </p>
              <p data-aos="zoom-in">
                Pixium is a global organization, with legal entities, business
                processes, management structures, and technical systems that
                cross borders. Therefore, where permitted by law, we may share
                your personal information within Pixium (including to
                subsidiaries, affiliates and parent companies) or our suppliers
                and business partners and may transfer it to other countries in
                the world where we do business (including, but not limited to,
                the United States of America, Europe, GCC and China). Regardless
                of where your information is transferred to and resides, our
                privacy and security practices are designed to provide
                protection for your personal information globally.
              </p>
              <p data-aos="zoom-in">
                Please note that some countries have privacy laws that are not
                equivalent to privacy laws in your own country. In such
                countries, Pixium will still handle information in the manner we
                describe here and will take such measures as are necessary to
                comply with applicable data protection laws when transferring
                and processing your information. Where required, Pixium will
                also maintain agreements between Pixium group companies or with
                Pixium partners or suppliers to govern the transferring,
                processing and protection of personal information.
              </p>
              <p data-aos="zoom-in">
                In addition to the privacy practices set out in this policy,
                Pixium’s Mobile Technologies business has established a set of
                “Binding Corporate Rules” for internal transfers between Pixium
                Mobile Technologies companies in the European Union and Pixium
                Mobile Technologies companies elsewhere. These have been
                approved by certain European Union Data Protection Authorities
                and are a commitment by Pixium Mobile Technologies to adequately
                protect personal information that Pixium Mobile Technologies
                processes regardless of where the information resides. A full
                copy of the rules can be found here. For additional information,
                please contact{" "}
                <a href="privacy@Pixiumworld.com.">privacy@Pixiumworld.com</a>.
              </p>
              <p data-aos="zoom-in">
                <li className="h3" data-aos="zoom-in">
                  Does Pixium use cookies, web beacons, analytics and related
                  technologies for online advertising and other purposes?
                </li>
              </p>
              <p data-aos="zoom-in">
                As is true of most websites, we collect certain information
                automatically and store it in log files. We sometimes collect
                information about our visitor’s behavior during their visits to
                our websites to help us provide better customer service, to
                improve the quality of our website experiences or to tailor
                advertising.
              </p>
              <p data-aos="zoom-in">
                For example, we keep track of the domains from which visitors
                come to our website, and we also measure visitor activity on
                Pixium websites, but we do so in ways that do not enable the
                actual identity of any visitor (we call this “non-identifiable
                information” below). Pixium or others on Pixium’s behalf may use
                this data to analyze trends and statistics, to improve our
                websites and to serve targeted advertising.
              </p>
              <p data-aos="zoom-in">
                <span>Clickstreams:</span>
              </p>
              <p data-aos="zoom-in">
                When we collect personal information from you in a transaction,
                we may extract some information about that transaction in a
                non-identifiable format and combine it with other
                non-identifiable information. This information is sometimes
                called a “clickstream”. This information is used and analyzed
                only at an aggregate level to help us understand trends and
                patterns. This information is not reviewed at an individual
                level.
              </p>
              <p data-aos="zoom-in">
                <span>Cookies:</span>
              </p>
              <p data-aos="zoom-in">
                In order to offer and provide a customized website experience,
                Pixium may use cookies, such as GoogleAnalytics and DoubleClick
                cookies, to store and help track information about you. Cookies
                are simply small pieces of data that are sent to your browser
                from a web server and stored on your computer’s hard drive.
                Pixium uses cookies to help remind us who you are and to help
                you navigate our sites during your visits. Cookies enable us to
                save passwords and preferences for you so you won’t have to
                re-enter them each time you visit. We also use cookies in order
                to determine relevant interest based advertisements to serve the
                user.
              </p>
              <p data-aos="zoom-in">
                Pixium may also use cookies to measure traffic patterns and
                which areas of the Pixium site you have visited and your
                visiting patterns. Pixium may use this information to understand
                how our users navigate our site and to determine common traffic
                patterns, including what site the user came from. We use this
                information to simplify site navigation, to make product
                recommendations and to help design our site in order to make
                your experience more efficient and enjoyable. The use of cookies
                is relatively standard. Most browsers are initially set up to
                accept cookies. However, if you prefer not to store cookies, you
                can choose to:
              </p>
              <ul data-aos="zoom-in">
                <li data-aos="zoom-in">not use our sites</li>
                <li data-aos="zoom-in">
                  set your browser to notify you when you receive a cookie
                </li>
                <li data-aos="zoom-in">
                  set your browser to notify you when you receive a cookie
                </li>
                <li data-aos="zoom-in">set your browser to refuse to accept cookies</li>
                <li data-aos="zoom-in">delete our cookies after visiting our site</li>
                <li data-aos="zoom-in">
                  browse our site using your browser’s anonymous usage setting
                  (called “Incognito” in Chrome, “InPrivate” for Internet
                  Explorer, “Private Browsing” in Firefox and Safari etc.).
                </li>
              </ul>
              <p data-aos="zoom-in">
                However, please note that certain Pixium websites may not
                respond to Do-Not-Track signals sent by certain browsers. You
                should also understand that some features of the Pixium website
                may not function properly if you don’t accept cookies.
              </p>
              <p data-aos="zoom-in">
                If you do not know how to control or delete cookies, or would
                like to opt-out of receiving certain targeted ads based on your
                browsing history, we recommend that you visit
                <span>http://www.networkadvertising.org/choices/</span>,
                <span>www.aboutads.info</span>, or
                <span>www.youronlinechoices.eu</span> for detailed guidance,
                including opt-out instructions.
              </p>
              <p data-aos="zoom-in">
                If you would like to opt-out of Google Analytics for Display
                Advertisers and/or opt out of the customized Google Display
                Network, you may do so by visiting Google’s Ads Preferences
                Manager. You may also wish to utilize the Google Analytics
                Opt-out Browser Add-on to opt-out of Google Analytics . For
                information on how to do this on the browser of your product or
                mobile device you will need to refer to your browser’s or mobile
                device’s help menu or product manual.
              </p>
              <p data-aos="zoom-in">
                For more information, please see “How to work with Cookies”.
              </p>
              <p data-aos="zoom-in">
                <span>Local Storage Objects:</span>
              </p>
              <p data-aos="zoom-in">
                Additionally, we may use local storage objects (LSOs) such as
                Flash cookies and HTML5 to store content information and
                preferences. Suppliers and partners with who provide certain
                features on our site or to display advertising based upon your
                web browsing activity also use LSOs such as HTML 5 to collect
                and store information. Various browsers may offer their own
                management tools for removing HTML5 LSOs. To manage Flash
                cookies, please click here.
              </p>
              <p data-aos="zoom-in">
                <span>Online Advertising:</span>
              </p>
              <p data-aos="zoom-in">
                Pixium may also use services hosted by companies, such as Google
                Analytics for Display Advertisers, Remarketing with Google
                Analytics, Google Display Network Impression Reporting,
                DoubleClick Campaign Manager and Google Analytics Demographic
                and Interest Reporting to assist in providing services to help
                us understand our customers’ use of our websites and products,
                and to deliver and manage interest-based advertisements. These
                services may collect information, such as your IP address, sent
                by your browser or via cookies (for information on how to
                control these cookies, see the heading “Cookies” above).
              </p>
              <p data-aos="zoom-in">
                This information may be used to measure website trends and to
                deliver a Pixium product advertisement while browsing a website
                including non-Pixium websites. These ads are delivered based on
                a user’s visit to the Pixium website. Pixium and its suppliers-,
                including Google and DoubleClick, use cookies to report how your
                ad impressions, other uses of ad services, and interactions with
                these ad impressions and ad services are related to visits to
                Pixium’s website.
              </p>
              <p data-aos="zoom-in">
                If you don’t want to have this information used for the purpose
                of serving you interest-based ads, you may opt-out. You will
                still continue to receive generic ads.
              </p>
              <p data-aos="zoom-in">
                <span>Adobe Marketing Cloud Device Co-Op:</span>
              </p>
              <p data-aos="zoom-in">
                Pixium participates in the Adobe Marketing Cloud Device
                Cooperative to better understand how you use certain websites
                and apps of ours across the various devices you use, and to
                deliver tailored promotions. Learn more about how Adobe does
                this and how you may opt-out of this program.
              </p>
              <p data-aos="zoom-in">
                <span>Web beacons and other technologies:</span>
              </p>
              <p data-aos="zoom-in">
                Some Pixium websites also use web beacon or other technologies
                such as java scripts and etags to better tailor those sites to
                provide better customer service. These technologies may be in
                use on a number of pages across Pixium websites. When a visitor
                accesses these pages, a non-identifiable notice of that visit is
                generated. This may be processed by us or by our suppliers.
                These web beacons usually work in conjunction with cookies. If
                you turn off cookies, web beacons and other technologies will
                still detect visits to these pages, but the notices they
                generate cannot be associated with other non-identifiable cookie
                information and are disregarded.
              </p>
              <p data-aos="zoom-in">
                <span>Personalized URL Links:</span>
              </p>
              <p data-aos="zoom-in">
                On occasion, we may personalize and customize websites for
                certain visitors. If you visit one of these sites, you may find
                it customized with references to products and/or services that
                we believe may be of interest to you, based on your previous
                interactions with Pixium and information you have provided to
                us. While you are visiting these websites, we may collect
                information about your visit to better tailor the site to your
                interests. An invitation to visit one of these websites can be
                presented as a personalized URL in an email, a notice on a
                website registration page, or as a response to you logging on to
                a certain website.
              </p>
              <p data-aos="zoom-in">
                If you choose to visit one of these websites, Pixium will
                collect information about your visits and associate with other
                information about you and your relationship with Pixium. If you
                do not want your information to be used in this way, please do
                not visit these sites.
              </p>
              <p data-aos="zoom-in">
                <li className="h3" data-aos="zoom-in">How is my information secured?</li>
              </p> 
              <p data-aos="zoom-in">
              Pixium implements appropriate technical and organizational
              measures and processes to protect your personal information and to
              maintain its quality both during transmission and once it is
              received. For example, we use encryption when transmitting
              sensitive information such as a credit card number to help us to
              keep your information secure.
              </p>
              <p data-aos="zoom-in">
                <li className="h3" data-aos="zoom-in">
                  How long will my personal information be retained?
                </li>
              </p>
              <p data-aos="zoom-in">
              We will retain your personal information only for as long as your
              account is active or as needed to provide you with services. We
              will also retain your personal information as necessary to comply
              with applicable legal obligations, to resolve disputes, and to
              enforce our agreements. However, please remember that no data
              transmission over the Internet can be guaranteed to be 100%
              secure. As a result, while we have taken appropriate steps to
              protect your personal information, Pixium cannot ensure or
              otherwise warrant the security of any information you provide to
              us or from our website and therefore you use our site at your own
              risk.
              </p>
              <p data-aos="zoom-in">
                <li className="h3" data-aos="zoom-in">What about my child’s privacy?</li>
              </p>
              <p data-aos="zoom-in">
              Websites operated by or on behalf of Pixium are intended for use
              by persons age 16 and over. Pixium does not seek to collect
              information about children under the age of 16. No information
              should be submitted to or posted on Pixium’s websites by users
              under 16 years of age. If a child under the age of 16 submits
              personal information to Pixium’s web sites, we delete the
              information as soon as we discover it was provided by a child
              under 16 years of age and do not use it for any purpose. Pixium
              encourages parents to take an active role in their child(ren)’s
              use of the Internet and to inform them of the potential dangers of
              providing information about themselves over the Internet.
              </p>
              <p data-aos="zoom-in">
                <li className="h3" data-aos="zoom-in">What are my data protection rights?</li>
              </p>
              <p data-aos="zoom-in">
              Applicable data protection laws may give you the right to control
              our use and processing of your personal information. These may
              include the right (i) to request access to and a copy of your
              personal information, (ii) to object to processing of your
              personal information, or to request rectification or erasure of
              your personal information; (iii) to restrict processing of your
              personal information; and (iv) to data portability. Where we are
              using your personal information with your consent, you also have
              the right to withdraw your consent at any time, though this will
              not affect our uses of your personal information prior to the
              withdrawal of your consent. To exercise any of your rights, please
              contact us at <span>privacy@Pixium.org</span>. We will consider
              and act upon any such requests in accordance with applicable data
              protection laws, and may ask you to verify your identity in order
              to help us respond efficiently to your request. You also have the
              right to raise questions or complaints with your applicable
              national data protection authority at any time.
              </p>
              <p data-aos="zoom-in">
                <li className="h3" data-aos="zoom-in">
                  How may I contact the Pixium Privacy Program?
                </li>
              </p>
              <p data-aos="zoom-in">
                If you have a question about this privacy policy, you can
                contact Pixium via{" "}
                <a href="mailto:privacy@pixiumworld.com">
                  privacy@pixiumworld.com
                </a>
                .
              </p>
            </ol>
          </div>
        </section>
      </div>
      <Footer />
      <BackToTop />
    </>
  );
}
