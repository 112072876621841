import React, { useState, useEffect } from "react";
import Mobiles from "../Components/Mobiles";
import "./Footer.css";

const Footer = () => {
  const [data, setData] = useState(Mobiles);
  const filterResult = (catItem) => {
    const result = Mobiles.filter((curData) => {
      return curData.Model === catItem;
    });
    setData(result);
    console.log(catItem);
  };
  {
    const addrss = "/";

    return (
      <>
        <footer className="mainfooter" role="contentinfo">
          <div className="footer-middle">
            <div className="container">
              <div className="row">
                <div className="col-md-3 col-sm-6">
                  <div className="footer-pad">
                    <h4>PRODUCTS</h4>
                    <ul className="list-unstyled">
                      <li>
                        <b>X Series</b>
                      </li>
                      <li>
                        <a
                          href="#products"
                          onClick={() => filterResult("X2")}
                        >
                          Pixium X2
                        </a>
                      </li>
                      <li>
                        <a
                          href={addrss + "#products"}
                          onClick={() => filterResult("X3")}
                        >
                          Pixium X3
                        </a>
                      </li>
                      <li>
                        <b>D series</b>
                      </li>
                      <li>
                        <a
                          href={addrss + "#products"}
                          onClick={() => filterResult("d20")}
                        >
                          Pixium D20s
                        </a>
                      </li>
                      <li>
                        <b>M series</b>
                      </li>
                      <li>
                        <a
                          href={addrss + "#products"}
                          onClick={() => filterResult("M20")}
                        >
                          Pixium M20
                        </a>
                      </li>
                      <li>
                        <a
                          href={addrss + "#products"}
                          onClick={() => filterResult("M30")}
                        >
                          Pixium M30
                        </a>
                      </li>
                      <li>
                        <b>Rugged series</b>
                      </li>

                      <li>
                        <a
                          href={addrss + "#products"}
                          onClick={() => filterResult("Rugged-XR1")}
                        >
                          Pixium Rugged XR1
                        </a>
                      </li>
                      <li>
                        <b>Tablets</b>
                      </li>
                      <li>
                        <a title="Pixium T15" href={addrss + "#Rproduct"}>
                          T15
                        </a>
                      </li>
                      <li>
                        <a title="Pixium T20" href={addrss + "#Rproduct"}>
                          T20
                        </a>
                      </li>
                      <li>
                        <a title="Pixium T30" href={addrss + "#Rproduct"}>
                          T30
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-md-3 col-sm-6">
                  <div className="footer-pad">
                    <h4>CONTACT</h4>
                    <ul className="list-unstyled">
                      <li>
                        <a href="About">About Us</a>
                      </li>
                      <li>
                        <a href="Contact">Contact Us</a>
                      </li>
                      <li>
                        <a href="Contact">Where To Buy</a>
                      </li>
                      <li>
                        <a href="Privacy">Privacy Policy</a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-md-3 col-sm-6">
                  <div className="footer-pad">
                    <h4>SUPPORT</h4>
                    <ul className="list-unstyled">
                      <li>
                        <a href="Contact">Support Center</a>
                      </li>
                      <li>
                        <a href="Contact">User Manual</a>
                      </li>

                      <li>
                        <a href="Contact">Software Download</a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-md-3">
                  <h4>Follow Us</h4>
                  <ul className="social-network social-circle">
                    <li>
                      <a
                        target="_blank"
                        href="https://www.facebook.com/"
                        className="icoFacebook"
                        title="Facebook"
                      >
                        <i className="fa-brands fa-linkedin fa-2xl"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        target="_blank"
                        href="https://www.twitter.com/"
                        className="icoTwitter"
                        title="Twitter"
                      >
                        <i className="fa-brands fa-square-twitter fa-2xl"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        target="_blank"
                        href="https://www.youtube.com/"
                        className="icoYouTube"
                        title="YouTube"
                      >
                        <i className="fa-brands fa-square-youtube fa-2xl"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <br />
              <div className="row">
                <div className="col-md-8"></div>
                <div className="col-md-4 copy">
                  <img
                    src={"/assets/img/pixium-logo.png"}
                    alt="..."
                    height="36"
                  />
                  <br />
                  <div>
                    <p className="foot">
                      Copyright © 2022 Pixium | All rights reserved
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </>
    );
  }
};
export default Footer;
