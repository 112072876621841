import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
//SLick Slider
import Slider from "react-slick";

//Styling
import "react-fontawesome";
import "../Components/responsive.css";

const RelatedProducts = () => {
  // Related Products
  const Rproduct = {
    dots: false,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    speed: 800,
    autoplaySpeed: 2000,
    cssEase: "linear",
    nextArrow: (
      <div>
        <div className="next-slick-arrow"> ⟩ </div>
      </div>
    ),
    prevArrow: (
      <div>
        <div className="prev-slick-arrow"> ⟨ </div>
      </div>
    ),
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
          dots: true,
          arrows: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          dots: true,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };
  return (
    <>
      <section className="hero text-center Rproduct text-uppercase">
        <h1 className="RP-Title">Related Products</h1>
        <div className="contianer slider">
          <Slider {...Rproduct} className="sliders">
            <div>
              <Link to={"/Mobiles/D20s"}>
                <img
                  src={"/assets/img/products/D20s.png"}
                  title="Pixium D20s"
                  alt="pixium D20s"
                  className="center img-fluid"
                />
              </Link>
            </div>

            <div>
              <Link to={"/Mobiles/X3"}>
                <img
                  src={"/assets/img/products/X3.png"}
                  title="Pixium X3"
                  alt="pixium X3"
                  className="center img-fluid"
                />
              </Link>
            </div>
            <div>
              <Link to={"/Mobiles/X2"}>
                <img
                  src={"/assets/img/products/X2.png"}
                  title="Pixium X2"
                  alt="pixium X2"
                  className="center img-fluid"
                />
                
              </Link>
            </div>
            <div>
              <Link to={"/Mobiles/M30"}>
                <img
                  src={"/assets/img/products/M30.png"}
                  title="Pixium M30"
                  alt="pixium M30"
                  className="center img-fluid"
                />
              </Link>
            </div>
            <div>
              <Link to={"/Mobiles/M20"}>
                <img
                  src={"/assets/img/products/M20.png"}
                  title="Pixium M20"
                  alt="pixium M20"
                  className="center img-fluid"
                />
              </Link>
            </div>

            <div>
              <Link to={"/Mobiles/Rugged-XR1"}>
                <img
                  src={"/assets/img/products/XR1.png"}
                  title="Pixium XR1"
                  alt="pixium XR1"
                  className="center img-fluid"
                />
              </Link>
            </div>
          </Slider>
        </div>
      </section>
    </>
  );
};
export default RelatedProducts;
