import React, { useRef } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
//Components
import BackToTop from "../Components/BackToTop";
import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";
import emailjs from "@emailjs/browser";

export default function About() {
  const form = useRef();

  const results = (e) => {
    if (e === "OK") {
      toast.success(
        "Thank you for your message. We will get back to you as soon as possible.",
        {
          position: toast.POSITION.TOP_RIGHT,
        }
      );
      form.current.reset();
    } else {
      toast.error("Something is wrong.", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };
  const buttonRef = useRef();
  const sendEmail = (e) => {
    buttonRef.current.disabled = true;
    console.log(e.currentTarget);
    e.preventDefault();
    
    emailjs
      .sendForm(
        "service_8lyunyq",
        "template_va15g0c",
        form.current,
        "Eko0mUMKfHS_ULLA8"
      )
      .then(
        form.current.reset(),
        (result) => {
          results(result.text);
        },
        (error) => {
          results(error.text);
        }
      );
  };

  return (
    <>
      <Navbar />
      <form ref={form} onSubmit={sendEmail}>
        <div className="container py-5">
          <div className="row py-5">
            <div className="row">
              <div className="form-group col-md-6 mb-3">
                <label htmlFor="inputname">Name <span className="text-danger">*</span></label>
                <input
                  type="text"
                  className="textbox form-control mt-1"
                  id="name"
                  name="user_Name"
                  placeholder="John Doe"
                  required={true}
                />
              </div>
              <div className="form-group col-md-6 mb-3">
                <label htmlFor="inputemail">Contact No <span className="text-danger">*</span></label>
                <input
                  type="tel"
                  className="textbox form-control mt-1"
                  id="number"
                  name="user_Number"
                  pattern="[0-9]{14}"
                  placeholder=" 00XXXXXXXXXXXX"
                  required={true}
                />
                <small className="text-danger">00XXXXXXXXXXXX</small>
              </div>
            </div>
            <div className="mb-3">
              <label htmlFor="inputsubject">Email <span className="text-danger">*</span></label>
              <input
                type="email"
                className=" textbox form-control mt-1"
                iid="email"
                name="user_Email"
                placeholder="john.doe@email.com"
                required={true}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="inputmessage">Message <span className="text-danger">*</span></label>
              <textarea
                className="textbox form-control mt-1"
                id="message"
                name="user_Message"
                placeholder="Say whatever you want."
                required={true}
                rows="8"
              ></textarea>
            </div>
            <div className="row">
              <div className="col text-end mt-2">
                <button
                  type="submit"
                  className="btn btn-success btn-lg px-3"
                  ref={buttonRef}
                  
                >
                  Let’s Talk
                </button>
                <ToastContainer />
              </div>
            </div>
          </div>
        </div>
      </form>
      <Footer />
      <BackToTop />
    </>
  );
}
