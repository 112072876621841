import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";
import RelatedProducts from "../Components/RelatedProducts";
//Styling
import "react-fontawesome";
import "./Home.css";
import "../Components/responsive.css";
//SLick Slider
import Slider from "react-slick";
export default function D20s() {
  const hero = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 1100,
    autoplaySpeed: 3000,
    cssEase: "linear",
    nextArrow: (
      <div>
        <div className="next-slick-arrow"> ⟩ </div>
      </div>
    ),
    prevArrow: (
      <div>
        <div className="prev-slick-arrow"> ⟨ </div>
      </div>
    ),
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
          dots: false,
          arrows: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          dots: false,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };
  // THumbnail Slider
  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);
  const [slider1, setSlider1] = useState(null);
  const [slider2, setSlider2] = useState(null);

  useEffect(() => {
    setNav1(slider1);
    setNav2(slider2);
  });
  return (
    <>
      <Navbar />
      <section className="conatiner text-center page-section mb-0 hero">
        <div className="contianer hero slider">
          <br />
          <Slider {...hero}>
            <div>
              <img
                src={"/assets/img/products/banner/product/1.jpeg"}
                alt="pixium"
                className="center img-fluid"
              />
            </div>
            <div>
              <img
                src={"/assets/img/products/banner/product/2.jpeg"}
                alt="pixium"
                className="center img-fluid"
              />
            </div>
          </Slider>
        </div>
      </section>
      <br />
      <section class="text-center">
        <h2 class="text-muted">PRXIUM D20s </h2>

        <div class="container-xxl py-5">
          <div class="container">
            <div class="row g-5">
              <div class="col-sm-12">
                <img
                  class="img-fluid"
                  src="/assets/img/products/Details/D20s/6.png"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="container-xxl py-5">
          <div class="container">
            <div class="row g-5">
              <div class="col-md-12">
                <div class="h-100">
                  <img
                    class="card-img rounded-0 img-fluid"
                    src="/assets/img/products/Details/D20s/7.png"
                  />

                  <h1 class=" pt-5">
                    The distance between each camera helpsto create a{" "}
                    <span style={{ color: "#e93b05;" }}>3D effect</span>
                  </h1>
                  <img
                    class="card-img rounded-0 img-fluid"
                    src="/assets/img/products/Details/D20s/8.jpg"
                  />

                  <h1 class=" pt-5">
                    Premium 4G Gaming HyperEngine{" "}
                    <span style={{ color: "#e93b05;" }}>CPU-G95 Octa core</span>{" "}
                    from MediaTek
                  </h1>
                  <h6> HyperEngine game technology</h6>
                  <p>
                    3D Movies, Streaming, Tele-conferencing and Gaming
                    experiences. MediaTek HyperEngine enhancements intelligent,
                    reliable connectivity for rapid gameplay to-end
                    optimizations for smooth gaming and more.
                  </p>
                  <img
                    class="card-img rounded-0 img-fluid"
                    src="/assets/img/products/Details/D20s/11.png"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="container-xxl py-5">
          <div class="container">
            <div class="row g-5">
              <div class="col-md-6">
                <h1 class=" pt-5">
                  {" "}
                  <span style={{ color: "#e93b05;" }}>8G+256G</span> Big
                  Capacity store everything you need
                </h1>
              </div>
              <div class="col-md-6 ig">
                <img
                  class="img-fluid"
                  src="/assets/img/products/Details/D20s/12.jpg"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>

        <div class="container-xxl py-5">
          <div class="container">
            <div class="row g-5">
              <div class="col-md-6">
                <img
                  class="img-fluid"
                  src="/assets/img/products/Details/D20s/13.jpg"
                  alt=""
                />
              </div>
              <div class="col-md-6">
                <h1 class=" pt-5">
                  18W <span style={{ color: "#e93b05;" }}>20X</span> the
                  cleanest optical zoom range across the system
                </h1>
              </div>
            </div>
          </div>
        </div>
        <div class="container-xxl py-5">
          <div class="container">
            <div class="row g-5">
              <div class="col-md-12">
                <div class="h-100">
                  <img
                    class="card-img rounded-0 img-fluid"
                    src="/assets/img/products/Details/D20s/1.jpg"
                  />
                  <img
                    class="card-img rounded-0 img-fluid"
                    src="/assets/img/products/Details/D20s/2.jpg"
                  />
                  <img
                    class="card-img rounded-0 img-fluid"
                    src="/assets/img/products/Details/D20s/3.jpg"
                  />
                  <img
                    class="card-img rounded-0 img-fluid"
                    src="/assets/img/products/Details/D20s/4.jpg"
                  />
                  <img
                    class="card-img rounded-0 img-fluid"
                    src="/assets/img/products/Details/D20s/5.jpg"
                  />
                  <p class="mb-4"> </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="container text-center ">
          <br />
          <Link to="/Contact" class="g-5 btn btn-outline-primary btn-lg">
            {" "}
            Where to buy
          </Link>
        </div>
      </section>

      <RelatedProducts />
      <Footer />
    </>
  );
}
