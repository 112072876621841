import * as React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Home from "./Pages/Home";
import About from "./Pages/About";
import Contact from "./Pages/Contact";
import Privacy from "./Pages/Privacy";
//Mobiles
import D20s from "./Pages/D20s";
import M20 from "./Pages/M20";
import M30 from "./Pages/M30";
import X2 from "./Pages/X2";
import X3 from "./Pages/X3";
import XR1 from "./Pages/XR1";
//Tablets

import T15 from "./Pages/T15";
import T20 from "./Pages/T20";
import T30 from "./Pages/T30";
function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="home" element={<Home />} />        
        <Route path="about" element={<About />} />
        <Route path="contact" element={<Contact />} />
        <Route path="Privacy" element={<Privacy />} />
        {/* Mobiles */}
        <Route path="Mobiles/D20s" element={<D20s />} />
        <Route path="Mobiles/Rugged-XR1" element={<XR1 />} />
        <Route path="Mobiles/X2" element={<X2 />} />
        <Route path="Mobiles/X3" element={<X3 />} />
        <Route path="Mobiles/M20" element={<M20 />} />
        <Route path="Mobiles/M30" element={<M30 />} />
        {/* Tablets */}
        <Route path="Tablets/T15" element={<T15 />} />
        <Route path="Tablets/T20" element={<T20 />} />
        <Route path="Tablets/T30" element={<T30 />} />
      
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </div>
  );
}
export default App;