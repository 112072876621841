import React, {useEffect} from 'react'
import BackToTop from "../Components/BackToTop";
import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";
import './Privacy.css'

import aos from "aos";
import "aos/dist/aos.css";
export default function About() {
  useEffect(()=>{
    aos.init({duration:1000}); 
  })
  return (
    <>
    <Navbar/>
    <header id="hero"></header>
      <h1 className='text-center'> About Us</h1>
  <section class="container py-5 Privacy">
    <div class="row  pt-5 pb-3">
      <p data-aos="zoom-in">
        The story behind PIXIUM was how to offer a 3D experience with an affordable smartphone. PIXIUM world has been
        created to build stylish, secure, and robust smartphones based on cutting-edge technologies with unique
        smartphone experience.
      </p>
      <p data-aos="zoom-in">
        PIXIUM World’s products are made in a very agile manner and with passion to deliver valuable smartphone
        experiences for all consumers.
      </p>
      <p data-aos="zoom-in">
        Pixium strives to assume social responsibilities proactively with <span>“Smartphone for Everyone”</span>. 
      </p>
      <p data-aos="zoom-in">
        PIXIUM World’s vision is to offer a unique smartphone experience that answers your dreams: the highest quality
        smartphones at the lowest possible prices to let people go beyond connecting with others.
      </p>
    </div>

  </section>
    <Footer/>
    <BackToTop/>
    </>
  )
}
