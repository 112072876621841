import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";
import RelatedProducts from "../Components/RelatedProducts";
//Styling
import "react-fontawesome";
import "./Home.css";
import "../Components/responsive.css";
//SLick Slider
import Slider from "react-slick";
export default function X2() {
  const hero = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 1100,
    autoplaySpeed: 3000,
    cssEase: "linear",
    nextArrow: (
      <div>
        <div className="next-slick-arrow"> ⟩ </div>
      </div>
    ),
    prevArrow: (
      <div>
        <div className="prev-slick-arrow"> ⟨ </div>
      </div>
    ),
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
          dots: false,
          arrows: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          dots: false,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };
  // THumbnail Slider
  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);
  const [slider1, setSlider1] = useState(null);
  const [slider2, setSlider2] = useState(null);

  useEffect(() => {
    setNav1(slider1);
    setNav2(slider2);
  });
  return (
    <>
      <Navbar />
      <section className="conatiner text-center page-section mb-0 hero">
        <div className="contianer hero slider">
          <br />
          <Slider {...hero}>
            <div>
              <img
                src={"/assets/img/products/banner/1.png"}
                alt="pixium"
                className="center img-fluid"
              />
            </div>
            <div>
              <img
                src={"/assets/img/products/T15/T15-7.png"}
                alt="pixium"
                className="center img-fluid"
              />
            </div>
            <div>
              <img
                src={"/assets/img/products/banner/2.png"}
                alt="pixium"
                className="center img-fluid"
              />
            </div>
            <div>
              <img
                src={"/assets/img/products/T15/T15-8.png"}
                alt="pixium"
                className="center img-fluid"
              />
            </div>
            <div>
              <img
                src={"/assets/img/products/banner/3.png"}
                alt="pixium"
                className="center img-fluid"
              />
            </div>
            <div>
              <img
                src={"/assets/img/products/T30/T30-1.jpg"}
                alt="pixium"
                className="center img-fluid"
              />
            </div>
            <div>
              <img
                src={"/assets/img/products/banner/4.png"}
                alt="pixium"
                className="center img-fluid"
              />
            </div>
            <div>
              <img
                src={"/assets/img/products/T30/T30-5.jpg"}
                alt="pixium"
                className="center img-fluid"
              />
            </div>
            <div>
              <img
                src={"/assets/img/products/banner/5.png"}
                alt="pixium"
                className="center img-fluid"
              />
            </div>
            <div>
              <img
                src={"/assets/img/products/T20/T20-1.png"}
                alt="pixium"
                className="center img-fluid"
              />
            </div>
          </Slider>
        </div>
      </section>
      <br />

      <section>
        <section class="text-center">
          <h2 class="text-muted">PRXIUM X2 </h2>

          <div class="container-xxl py-5">
            <div class="container">
              <div class="row g-5">
                <div class="col-sm-12 ">
                  <div class="h-100">
                    <img
                      class="img-fluid"
                      src="/assets/img/products/Details/X2/Pixium-X2-7.png"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="container-xxl py-5">
            <div class="container">
              <div class="row g-5">
                <div class="col-md-12 ">
                  <div class="h-100" >
                    <h1 class="text-muted">Latest Octa Core CPU- FAST SPEED</h1>
                    <img
                      class="card-img rounded-0 img-fluid"
                      src="/assets/img/products/Details/X2/Pixium-X2-5.png"
                    />
                    <p class="mb-4"> </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="container-xxl py-5">
            <div class="container">
              <div class="row g-5">
                <div class="col-md-12 ">
                  <div class="h-100" >
                    <h1 class="text-muted">
                      48MP main camera - give you a wonderful Shooting
                      experience
                    </h1>
                    <img
                      class="img-fluid "
                      src="/assets/img/products/Details/X2/Pixium-X2-6.jpg"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="container">
            <div class="row">
              <div class="col-md-6  ">
                <img
                  class="card-img rounded-0 img-fluid "
                  
                  src="/assets/img/products/Details/X2/Pixium-X2-10.png"
                />
              </div>
              <div class="col-md-6  ">
                <img
                  class="card-img rounded-0 img-fluid "
                  
                  src="/assets/img/products/Details/X2/Pixium-X2-11.png"
                />
              </div>
            </div>
          </div>
        </section>
        <div class="gradientContainer">
          <div class="container pt-5">
            <div class="row">
              <div class="col-md-8 text-light">
                <h1>Long battery life with</h1>
                <h4>Battery life up to two days with normal use.</h4>
              </div>
              <div class="col-md-4 text-dark">
                <h1>5000 mAh.</h1>
                <h4>22 Hours Video</h4>
                <h4>43 Hours Calls</h4>
              </div>
            </div>
          </div>
        </div>

        <div class="container text-center ">
          <br />
          <Link to="/Contact" class="g-5 btn btn-outline-primary btn-lg"> Where to buy</Link>
         
        </div>
      </section>

      <RelatedProducts />
      <Footer />
    </>
  );
}
