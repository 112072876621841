const Mobiles = [
  //   D20s
  {
    id: 1,
    title: "Pixium D20s",
    Model: "D20s",
    //Specs
    screen: '6.5" FHD+, IPS, 1080*2400 3D',
    battery: "4200 mAh ",
    weight: "161*76*8.8MM 205g",
    ram: "8 GB",
    storage: "256 GB",
    Fcamera: "25.0 MP",
    Bcamera: "16M*2 3D+8M(wide angle)",
    IO: "Type C, 3.5mm audio jack",
    Sensore: "G-3D/ P/ L/ Fingerprint/ NCF/ face unlock",

    //Images
    alt: "Pixium D20s",
    image: "assets/img/products/D20s.png",

    // mobile Images
    mobileImage1: "assets/img/products/D20s/mobile/D20s-1.png",
    mobileImage2: "assets/img/products/D20s/mobile/D20s-2.png",
    mobileImage3: "assets/img/products/D20s/mobile/D20s-3.png",
    mobileImage4: "assets/img/products/D20s/mobile/D20s-4.png",
    mobileImage5: "assets/img/products/D20s/mobile/D20s-2.png",

    //Flayers
    mobileFlayer1: "assets/img/products/D20s/flayers/D20s-1.png",
    mobileFlayer2: "assets/img/products/D20s/flayers/D20s-2.png",
    mobileFlayer3: "assets/img/products/D20s/flayers/D20s-3.png",

    //Features
    network: "assets/img/products/Specs/5g.svg",
    networkTitel: "2G / 3G",
    sims: "assets/img/products/Specs/dualSim.svg",
    simsTitle: "2 Sims",
    os: "assets/img/products/Specs/androidOs.svg",
    osTitle: "Android",
    batteryS: "assets/img/products/Specs/battery.svg",
    size: "assets/img/products/Specs/size.svg",
    sizeTitle: "5.99",
    ramS: "assets/img/products/Specs/ram.svg",
    romS: "assets/img/products/Specs/rom.svg",
    wifi: "assets/img/products/Specs/wifi.svg",
    wifiTitle: "2.4 & 5.0G",
    scanner: "assets/img/products/Specs/retina.svg",
    scannerTitle: "Retina",
    water: "assets/img/products/Specs/water.svg",
    waterTitle: "WaterProof",
    frontCam: "assets/img/products/Specs/frontCam.svg",
    backCam: "assets/img/products/Specs/backCam.svg",
    backCamTitle: "16.0 MP",
    charger: "assets/img/products/Specs/typeC.svg",
    chargerTitle: "Type C",
    finger: "assets/img/products/Specs/finger.svg",
    fingerTitle: "Fingerprint",
    cpu: "assets/img/products/Specs/cpu.svg",
    cpuTitle: "MTK6785 (G95)",
  },

  //   M20
  {
    id: 2,
    title: "Pixium M20",
    Model: "M20",

    //Specs
    screen: '5.99" HD+, IPS, 720*1440',
    battery: "5000 mAh ",
    weight: "162.5*77.5*11.2MM 190g",
    ram: "4 GB",
    storage: "64 GB",
    Fcamera: "5.0 MP",
    Bcamera: "13.0 M",
    IO: "Type C, 3.5mm audio jack",
    Sensore: "G-3D/ P/ L",

    //Images
    alt: "Pixium M20",
    image: "assets/img/products/d20s.png",

    // mobile Images
    mobileImage1: "assets/img/products/M20/mobile/M20-1.png",
    mobileImage2: "assets/img/products/M20/mobile/M20-2.png",
    mobileImage3: "assets/img/products/M20/mobile/M20-3.png",
    mobileImage4: "assets/img/products/M20/mobile/M20-4.png",
    mobileImage5: "assets/img/products/M20/mobile/M20-2.png",

    //Flayers
    mobileFlayer1: "assets/img/products/M20/flayers/M20-1.jpg",
    mobileFlayer2: "assets/img/products/M20/flayers/M20-2.jpg",
    mobileFlayer3: "assets/img/products/M20/flayers/M20-3.jpg",

    //Features
    network: "assets/img/products/Specs/5g.svg",
    networkTitel: "2G / 3G",
    sims: "assets/img/products/Specs/dualSim.svg",
    simsTitle: "2 Sims",
    os: "assets/img/products/Specs/androidOs.svg",
    osTitle: "Android",
    batteryS: "assets/img/products/Specs/battery.svg",
    size: "assets/img/products/Specs/size.svg",
    sizeTitle: "5.99",
    ramS: "assets/img/products/Specs/ram.svg",
    romS: "assets/img/products/Specs/rom.svg",
    wifi: "assets/img/products/Specs/wifi.svg",
    wifiTitle: "2.4 & 5.0G",
    scanner: "assets/img/products/Specs/retina.svg",
    scannerTitle: "Retina",
    water: "assets/img/products/Specs/water.svg",
    waterTitle: "WaterProof",
    frontCam: "assets/img/products/Specs/frontCam.svg",
    backCam: "assets/img/products/Specs/backCam.svg",
    backCamTitle: "16 MP",
    charger: "assets/img/products/Specs/typeC.svg",
    chargerTitle: "Type C",
    finger: "assets/img/products/Specs/finger.svg",
    fingerTitle: "Fingerprint",
    cpu: "assets/img/products/Specs/cpu.svg",
    cpuTitle: "Core Core",
  },

  //   M30
  {
    id: 3,
    title: "Pixium M30",
    Model: "M30",

    //Specs
    screen: '6.5" HD+, IPS, 720*1560',
    battery: "5000 mAh ",
    weight: "165.4*76.95MM 195g",
    ram: "4 GB",
    storage: "64 GB",
    Fcamera: "5.0 MP",
    Bcamera: "13.0MP + VGA",
    IO: "Type C, 3.5mm audio jack",
    Sensore: "G-3D/ P/ L/Side Fingerprint",

    //Images
    alt: "Pixium M30",
    image: "assets/img/products/d20s.png",

    // mobile Images
    mobileImage1: "assets/img/products/M30/mobile/M30-1.png",
    mobileImage2: "assets/img/products/M30/mobile/M30-2.png",
    mobileImage3: "assets/img/products/M30/mobile/M30-3.png",
    mobileImage4: "assets/img/products/M30/mobile/M30-4.png",
    mobileImage5: "assets/img/products/M30/mobile/M30-5.png",

    //Flayers
    mobileFlayer1: "assets/img/products/M30/flayers/M30-1.jpg",
    mobileFlayer2: "assets/img/products/M30/flayers/M30-2.png",
    mobileFlayer3: "assets/img/products/M30/flayers/M30-3.jpg",

    //Features
    network: "assets/img/products/Specs/5g.svg",
    networkTitel: "2G / 3G",
    sims: "assets/img/products/Specs/dualSim.svg",
    simsTitle: "2 Sims",
    os: "assets/img/products/Specs/androidOs.svg",
    osTitle: "Android",
    batteryS: "assets/img/products/Specs/battery.svg",
    size: "assets/img/products/Specs/size.svg",
    sizeTitle: "5.99",
    ramS: "assets/img/products/Specs/ram.svg",
    romS: "assets/img/products/Specs/rom.svg",
    wifi: "assets/img/products/Specs/wifi.svg",
    wifiTitle: "2.4 & 5.0G",
    scanner: "assets/img/products/Specs/retina.svg",
    scannerTitle: "Retina",
    water: "assets/img/products/Specs/water.svg",
    waterTitle: "WaterProof",
    frontCam: "assets/img/products/Specs/frontCam.svg",
    backCam: "assets/img/products/Specs/backCam.svg",
    backCamTitle: "16.0 MP",
    charger: "assets/img/products/Specs/typeC.svg",
    chargerTitle: "Type C",
    finger: "assets/img/products/Specs/finger.svg",
    fingerTitle: "Fingerprint",
    cpu: "assets/img/products/Specs/cpu.svg",
    cpuTitle: "Core Core",
  },

  //   Rugged-XR1
  {
    id: 4,
    title: "Pixium Rugged XR1",
    Model: "Rugged-XR1",

    //Specs
    screen: '6.088" HD+, IPS, 720*1560',
    battery: "6000mAh",
    weight: "165.4*78.1*15.1MM 350g",
    ram: "6 GB",
    storage: "256 GB",
    Fcamera: "8.0 MP",
    Bcamera: "16.0M +2M",
    IO: "Type C, 3.5mm audio jack",
    Sensore:
      "G-3D/ P/ L/ Fingerprint/ NCF/ Wireless Charging/ PTT face unlock/ Reverse wireless charging",

    //Images
    alt: "Pixium Rugged XR1",
    image: "assets/img/products/d20s.png",

    // mobile Images
    mobileImage1: "assets/img/products/XR1/mobile/XR1-1.jpg",
    mobileImage2: "assets/img/products/XR1/mobile/XR1-2.png",
    mobileImage3: "assets/img/products/XR1/mobile/XR1-3.png",
    mobileImage4: "assets/img/products/XR1/mobile/XR1-4.png",
    mobileImage5: "assets/img/products/XR1/mobile/XR1-5.png",

    //Flayers
    mobileFlayer1: "assets/img/products/XR1/flayers/XR1-1.png",
    mobileFlayer2: "assets/img/products/XR1/flayers/XR1-2.png",
    mobileFlayer3: "assets/img/products/XR1/flayers/XR1-3.png",
    //Features
    network: "assets/img/products/Specs/5g.svg",
    networkTitel: "2G / 3G / 4G",
    sims: "assets/img/products/Specs/dualSim.svg",
    simsTitle: "2 Sims + TF",
    os: "assets/img/products/Specs/androidOs.svg",
    osTitle: "Android",
    batteryS: "assets/img/products/Specs/battery.svg",
    size: "assets/img/products/Specs/size.svg",
    sizeTitle: "5.99",
    ramS: "assets/img/products/Specs/ram.svg",
    romS: "assets/img/products/Specs/rom.svg",
    wifi: "assets/img/products/Specs/wifi.svg",
    wifiTitle: "Wifi 2.4",
    scanner: "assets/img/products/Specs/retina.svg",
    scannerTitle: "Retina",
    water: "assets/img/products/Specs/water.svg",
    waterTitle: "WaterProof",
    frontCam: "assets/img/products/Specs/frontCam.svg",
    backCam: "assets/img/products/Specs/backCam.svg",
    backCamTitle: "16 MP",
    charger: "assets/img/products/Specs/typeC.svg",
    chargerTitle: "Type C",
    finger: "assets/img/products/Specs/finger.svg",
    fingerTitle: "Fingerprint",
    cpu: "assets/img/products/Specs/cpu.svg",
    cpuTitle: "UC T610",
  },

  //   X2
  {
    id: 5,
    title: "Pixium X2",
    Model: "X2",

    //Specs
    screen: '6.5" HD+, IPS, 720*1600',
    battery: "5000mAh",
    weight: "167.4*77.3*9.21MM 195g",
    ram: "6 GB",
    storage: "128 GB",
    Fcamera: "16.0 MP",
    Bcamera: "48.0M + 5.0M(wide angle) + 2.0(microspur) +2.0(macro camera)",
    IO: "Type C, 3.5mm audio jack",
    Sensore: "G-3D/P/L/Side Fingerprint",

    //Images
    alt: "Pixium X2",
    image: "assets/img/products/X2.png",

    // mobile Images
    mobileImage1: "assets/img/products/X2/mobile/X2-1.png",
    mobileImage2: "assets/img/products/X2/mobile/X2-2.png",
    mobileImage3: "assets/img/products/X2/mobile/X2-3.png",
    mobileImage4: "assets/img/products/X2/mobile/X2-4.png",
    mobileImage5: "assets/img/products/X2/mobile/X2-2.png",

    //Flayers
    mobileFlayer1: "assets/img/products/X2/flayers/X2-1.png",
    mobileFlayer2: "assets/img/products/X2/flayers/X2-2.png",
    mobileFlayer3: "assets/img/products/X2/flayers/X2-3.png",

    //Features
    network: "assets/img/products/Specs/5g.svg",
    networkTitel: "2G / 3G / 4G",
    sims: "assets/img/products/Specs/dualSim.svg",
    simsTitle: "2 Sims + TF",
    os: "assets/img/products/Specs/androidOs.svg",
    osTitle: "Android",
    batteryS: "assets/img/products/Specs/battery.svg",
    size: "assets/img/products/Specs/size.svg",
    sizeTitle: "5.99",
    ramS: "assets/img/products/Specs/ram.svg",
    romS: "assets/img/products/Specs/rom.svg",
    wifi: "assets/img/products/Specs/wifi.svg",
    wifiTitle: "wifi 2.4G",
    scanner: "assets/img/products/Specs/retina.svg",
    scannerTitle: "Retina",
    water: "assets/img/products/Specs/water.svg",
    waterTitle: "WaterProof",
    frontCam: "assets/img/products/Specs/frontCam.svg",
    backCam: "assets/img/products/Specs/backCam.svg",
    backCamTitle: "48 MP",
    charger: "assets/img/products/Specs/typeC.svg",
    chargerTitle: "Type C",
    finger: "assets/img/products/Specs/finger.svg",
    fingerTitle: "Fingerprint",
    cpu: "assets/img/products/Specs/cpu.svg",
    cpuTitle: "UC T610 Octa Core",
  },

  //   X3
  {
    id: 6,
    title: "Pixium X3",
    Model: "X3",

    //Specs
    screen: "6.5' Curved FHD+ OLED, IPS, 1080*2340",
    battery: "4000 mAh ",
    weight: "159.3*74.3*8.3MM 180g",
    ram: "6 GB",
    storage: "256 GB",
    Fcamera: "8.0 MP",
    Bcamera: "48.0M + 5M + 2M +VGA, quad flash",
    IO: "Type C, 3.5mm audio jack",
    Sensore: "G-3D/P/L/Side Fingerprint",

    //Images
    alt: "Pixium X3",
    image: "assets/img/products/d20s.png",

    // mobile Images
    mobileImage1: "assets/img/products/X3/mobile/X3-1.jpg",
    mobileImage2: "assets/img/products/X3/mobile/X3-2.png",
    mobileImage3: "assets/img/products/X3/mobile/X3-3.png",
    mobileImage4: "assets/img/products/X3/mobile/X3-4.jpg",
    mobileImage5: "assets/img/products/X3/mobile/X3-5.png",

    //Flayers
    mobileFlayer1: "assets/img/products/X3/flayers/X3-1.jpg",
    mobileFlayer2: "assets/img/products/X3/flayers/X3-2.jpg",
    mobileFlayer3: "assets/img/products/X3/flayers/X3-3.png",
    //Features
    network: "assets/img/products/Specs/5g.svg",
    networkTitel: "2G/3G/4G",
    sims: "assets/img/products/Specs/dualSim.svg",
    simsTitle: "2 Sims",
    os: "assets/img/products/Specs/androidOs.svg",
    osTitle: "Android",
    batteryS: "assets/img/products/Specs/battery.svg",
    size: "assets/img/products/Specs/size.svg",
    sizeTitle: "5.99",
    ramS: "assets/img/products/Specs/ram.svg",
    romS: "assets/img/products/Specs/rom.svg",
    wifi: "assets/img/products/Specs/wifi.svg",
    wifiTitle: "2.4G /5.0G",
    scanner: "assets/img/products/Specs/retina.svg",
    scannerTitle: "Retina",
    water: "assets/img/products/Specs/water.svg",
    waterTitle: "WaterProof",
    frontCam: "assets/img/products/Specs/frontCam.svg",
    backCam: "assets/img/products/Specs/backCam.svg",
    backCamTitle: "48 MP",
    charger: "assets/img/products/Specs/typeC.svg",
    chargerTitle: "Type C",
    finger: "assets/img/products/Specs/finger.svg",
    fingerTitle: "Fingerprint",
    cpu: "assets/img/products/Specs/cpu.svg",
    cpuTitle: "MTK6785 (G95)",
  },

  //======================== Tablets ========================
  //   T15
  {
    id: 7,
    title: "Pixium T15",
    Model: "T15",

    //Specs
    screen: "6.5' Curved FHD+ OLED, IPS, 1080*2340",
    battery: "4000 mAh ",
    weight: "159.3*74.3*8.3MM 180g",
    ram: "6 GB",
    storage: "256 GB",
    Fcamera: "8.0 MP",
    Bcamera: "48.0M + 5M + 2M +VGA, quad flash",
    IO: "Type C, 3.5mm audio jack",
    Sensore: "G-3D/P/L/Side Fingerprint",

    //Images
    alt: "Pixium X3",
    image: "assets/img/products/d20s.png",

    // mobile Images
    mobileImage1: "assets/img/products/X3/mobile/X3-1.png",
    mobileImage2: "assets/img/products/X3/mobile/X3-2.png",
    mobileImage3: "assets/img/products/X3/mobile/X3-3.png",
    mobileImage4: "assets/img/products/X3/mobile/X3-4.png",
    mobileImage5: "assets/img/products/X3/mobile/X3-5.png",

    //Flayers
    mobileFlayer1: "assets/img/products/X3/flayers/X3-1.png",
    mobileFlayer2: "assets/img/products/X3/flayers/X3-2.png",
    mobileFlayer3: "assets/img/products/X3/flayers/X3-3.png",
    //Features
    network: "assets/img/products/Specs/5g.svg",
    networkTitel: "2G/3G/4G",
    sims: "assets/img/products/Specs/dualSim.svg",
    simsTitle: "2 Sims",
    os: "assets/img/products/Specs/androidOs.svg",
    osTitle: "Android",
    batteryS: "assets/img/products/Specs/battery.svg",
    size: "assets/img/products/Specs/size.svg",
    sizeTitle: "5.99",
    ramS: "assets/img/products/Specs/ram.svg",
    romS: "assets/img/products/Specs/rom.svg",
    wifi: "assets/img/products/Specs/wifi.svg",
    wifiTitle: "2.4G /5.0G",
    scanner: "assets/img/products/Specs/retina.svg",
    scannerTitle: "Retina",
    water: "assets/img/products/Specs/water.svg",
    waterTitle: "WaterProof",
    frontCam: "assets/img/products/Specs/frontCam.svg",
    backCam: "assets/img/products/Specs/backCam.svg",
    backCamTitle: "48 MP",
    charger: "assets/img/products/Specs/typeC.svg",
    chargerTitle: "Type C",
    finger: "assets/img/products/Specs/finger.svg",
    fingerTitle: "Fingerprint",
    cpu: "assets/img/products/Specs/cpu.svg",
    cpuTitle: "MTK6785 (G95)",
  },

  //   T20
  {
    id: 8,
    title: "Pixium T20",
    Model: "T20",

    //Specs
    screen: "6.5' Curved FHD+ OLED, IPS, 1080*2340",
    battery: "4000 mAh ",
    weight: "159.3*74.3*8.3MM 180g",
    ram: "6 GB",
    storage: "256 GB",
    Fcamera: "8.0 MP",
    Bcamera: "48.0M + 5M + 2M +VGA, quad flash",
    IO: "Type C, 3.5mm audio jack",
    Sensore: "G-3D/P/L/Side Fingerprint",

    //Images
    alt: "Pixium X3",
    image: "assets/img/products/d20s.png",

    // mobile Images
    mobileImage1: "assets/img/products/X3/mobile/X3-1.png",
    mobileImage2: "assets/img/products/X3/mobile/X3-2.png",
    mobileImage3: "assets/img/products/X3/mobile/X3-3.png",
    mobileImage4: "assets/img/products/X3/mobile/X3-4.png",
    mobileImage5: "assets/img/products/X3/mobile/X3-5.png",

    //Flayers
    mobileFlayer1: "assets/img/products/X3/flayers/X3-1.png",
    mobileFlayer2: "assets/img/products/X3/flayers/X3-2.png",
    mobileFlayer3: "assets/img/products/X3/flayers/X3-3.png",
    //Features
    network: "assets/img/products/Specs/5g.svg",
    networkTitel: "2G/3G/4G",
    sims: "assets/img/products/Specs/dualSim.svg",
    simsTitle: "2 Sims",
    os: "assets/img/products/Specs/androidOs.svg",
    osTitle: "Android",
    batteryS: "assets/img/products/Specs/battery.svg",
    size: "assets/img/products/Specs/size.svg",
    sizeTitle: "5.99",
    ramS: "assets/img/products/Specs/ram.svg",
    romS: "assets/img/products/Specs/rom.svg",
    wifi: "assets/img/products/Specs/wifi.svg",
    wifiTitle: "2.4G /5.0G",
    scanner: "assets/img/products/Specs/retina.svg",
    scannerTitle: "Retina",
    water: "assets/img/products/Specs/water.svg",
    waterTitle: "WaterProof",
    frontCam: "assets/img/products/Specs/frontCam.svg",
    backCam: "assets/img/products/Specs/backCam.svg",
    backCamTitle: "48 MP",
    charger: "assets/img/products/Specs/typeC.svg",
    chargerTitle: "Type C",
    finger: "assets/img/products/Specs/finger.svg",
    fingerTitle: "Fingerprint",
    cpu: "assets/img/products/Specs/cpu.svg",
    cpuTitle: "MTK6785 (G95)",
  },

  //   T30
  {
    id: 9,
    title: "Pixium T30",
    Model: "T30",

    //Specs
    screen: "6.5' Curved FHD+ OLED, IPS, 1080*2340",
    battery: "4000 mAh ",
    weight: "159.3*74.3*8.3MM 180g",
    ram: "6 GB",
    storage: "256 GB",
    Fcamera: "8.0 MP",
    Bcamera: "48.0M + 5M + 2M +VGA, quad flash",
    IO: "Type C, 3.5mm audio jack",
    Sensore: "G-3D/P/L/Side Fingerprint",

    //Images
    alt: "Pixium X3",
    image: "assets/img/products/d20s.png",

    // mobile Images
    mobileImage1: "assets/img/products/X3/mobile/X3-1.png",
    mobileImage2: "assets/img/products/X3/mobile/X3-2.png",
    mobileImage3: "assets/img/products/X3/mobile/X3-3.png",
    mobileImage4: "assets/img/products/X3/mobile/X3-4.png",
    mobileImage5: "assets/img/products/X3/mobile/X3-5.png",

    //Flayers
    mobileFlayer1: "assets/img/products/X3/flayers/X3-1.png",
    mobileFlayer2: "assets/img/products/X3/flayers/X3-2.png",
    mobileFlayer3: "assets/img/products/X3/flayers/X3-3.png",
    //Features
    network: "assets/img/products/Specs/5g.svg",
    networkTitel: "2G/3G/4G",
    sims: "assets/img/products/Specs/dualSim.svg",
    simsTitle: "2 Sims",
    os: "assets/img/products/Specs/androidOs.svg",
    osTitle: "Android",
    batteryS: "assets/img/products/Specs/battery.svg",
    size: "assets/img/products/Specs/size.svg",
    sizeTitle: "5.99",
    ramS: "assets/img/products/Specs/ram.svg",
    romS: "assets/img/products/Specs/rom.svg",
    wifi: "assets/img/products/Specs/wifi.svg",
    wifiTitle: "2.4G /5.0G",
    scanner: "assets/img/products/Specs/retina.svg",
    scannerTitle: "Retina",
    water: "assets/img/products/Specs/water.svg",
    waterTitle: "WaterProof",
    frontCam: "assets/img/products/Specs/frontCam.svg",
    backCam: "assets/img/products/Specs/backCam.svg",
    backCamTitle: "48 MP",
    charger: "assets/img/products/Specs/typeC.svg",
    chargerTitle: "Type C",
    finger: "assets/img/products/Specs/finger.svg",
    fingerTitle: "Fingerprint",
    cpu: "assets/img/products/Specs/cpu.svg",
    cpuTitle: "MTK6785 (G95)",
  },




];
export default Mobiles;
