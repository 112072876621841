import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Navbar from "../Components/Navbar";
import './x3.css';
import Footer from "../Components/Footer";
import RelatedProducts from "../Components/RelatedProducts";
//Styling
import "react-fontawesome";
import "./Home.css";
import "../Components/responsive.css";
//SLick Slider
import Slider from "react-slick";
export default function X3() {
  return (
    <>
      <Navbar />
      <section className="conatiner text-center page-section mb-0 hero">
        <div className="contianer x3Video">
          <video autoPlay muted controls loop  className="contianer">
            <source
              src="/assets/img/products/Details/X3/x3.mp4"
              type="video/mp4"
            />
          </video>
        </div>
      </section>
      <br />
      <section>
        <div class="containered py-5 text-center">
          <div class="container">
            <div class="row g-5">
              <div class="col-sm-12  ">
                <h2>PRXIUM X3 </h2>
                <img
                  class="img-fluid"
                  src="/assets/img/products/Details/X3/3.png"
                />
              </div>
            </div>
          </div>
          <div class="container">
            <div class="row g-5">
              <div class="col-md-12  ">
                <h3 class=" pt-5">More color for choice</h3>
                <div class="row">
                  <img
                    class="col-md-4 pt-2"
                    src="/assets/img/products/Details/X3/4.jpg"
                  />
                  <img
                    class="col-md-4 pt-2"
                    src="/assets/img/products/Details/X3/5.jpg"
                  />
                  <img
                    class="col-md-4 pt-2"
                    src="/assets/img/products/Details/X3/6.jpg"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="container">
            <div class="row g-5">
              <div class="col-md-12">
                <div class="h-100">
                  <h3 class=" pt-5">
                    {" "}
                    <span style={{ color: "#e93b05;" }}>48MP</span> main
                    camera-give you a wonderfulShooting experience
                  </h3>
                  <img
                    class="card-img rounded-0 img-fluid"
                    src="/assets/img/products/Details/X3/7.jpg"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="containered">
          <div class="container">
            <div class="row g-5">
              <div class="col-md-6">
                <img
                  class="img-fluid"
                  src="/assets/img/products/Details/X3/8.png"
                  alt=""
                />
              </div>
              <div class="col-md-6 pt-5" style={{ paddingTop: "100px ;" }}>
                <h3 class=" ">
                  Curved <span style={{ color: "#e93b05;" }}>2K FHD+OLED</span>{" "}
                  Screen
                </h3>
                <p>
                  The 48MP main rear camera works in conjunction with a 5MP Wide
                  angle lens and a depth sensor, so you can capture stunning
                  images with splendid colors and details. The main camera helps
                  you capture every delightful moment in your journey, while the
                  macro lens focuses on those ultra-fine astonishing details.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="containered">
          <div class="container">
            <div class="row g-0">
              <div class="col-md-6">
                <img
                  class="img-fluid"
                  src="/assets/img/products/Details/X3/9.jpg"
                  alt=""
                />
              </div>
              <div class="col-md-6 ">
                <img
                  class="img-fluid"
                  src="/assets/img/products/Details/X3/10.jpg"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
        <div class="containered">
          <div class="container">
            <div class="row g-0">
              <div class="col-md-6">
                <img
                  class="img-fluid"
                  src="/assets/img/products/Details/X3/11.jpg"
                  alt=""
                />
              </div>
              <div class="col-md-6 ">
                <img
                  class="img-fluid bg-dark h-100"
                  src="/assets/img/products/Details/X3/15.jpg"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>

        <div class="containered">
          <div class="container">
            <div
              id="pixium-mob-pixium-hero-carousels"
              class="carousel slide"
              data-bs-ride="carousel"
            >
              <ol class="carousel-indicators">
                <li
                  data-bs-target="#pixium-mob-pixium-hero-carousels"
                  data-bs-slide-to="0"
                  class="active"
                ></li>
                <li
                  data-bs-target="#pixium-mob-pixium-hero-carousels"
                  data-bs-slide-to="1"
                ></li>
                <li
                  data-bs-target="#pixium-mob-pixium-hero-carousels"
                  data-bs-slide-to="2"
                ></li>
              </ol>
              <div class="carousel-inner text-center">
                <div class="carousel-item active">
                  <div class="container">
                    <img
                      class="img-fluid"
                      src="/assets/img/products/Details/X3/12.png"
                      alt=""
                    />
                  </div>
                </div>

                <div class="carousel-item">
                  <div class="container">
                    <img
                      class="img-fluid"
                      src="/assets/img/products/Details/X3/11.png"
                      alt=""
                    />
                  </div>
                </div>

                <div class="carousel-item">
                  <div class="container">
                    <img
                      class="img-fluid"
                      src="/assets/img/products/Details/X3/10.png"
                      alt=""
                    />
                  </div>
                </div>
              </div>
              <a
                class="carousel-control-prev text-decoration-none w-auto ps-3"
                href="#pixium-mob-pixium-hero-carousels"
                role="button"
                data-bs-slide="prev"
              >
                <i class="fas fa-chevron-left"></i>
              </a>
              <a
                class="carousel-control-next text-decoration-none w-auto pe-3"
                href="#pixium-mob-pixium-hero-carousels"
                role="button"
                data-bs-slide="next"
              >
                <i class="fas fa-chevron-right"></i>
              </a>
            </div>
          </div>
        </div>
        <div class="container text-center ">
          <br />
          <Link to="/Contact" class="g-5 btn btn-outline-primary btn-lg">
            {" "}
            Where to buy
          </Link>
        </div>
      </section>

      <RelatedProducts />
      <Footer />
    </>
  );
}
