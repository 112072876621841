const Tablets = [
  //   Titels
  //   T15
  {
    id: 1,
    title: "Pixium T15",
    subtitle: "economic",

    Model: "T15",

    //Specs
    platform: "A133 Quad Core 4*A53 1.5GHz",
    screen: '10.1" HD, IPS, 800*1280',
    ram: "3GB",
    rom: "32GB",
    os: "Android 12",
    weight: "248*165*9.4MM",
    Fcamera: "2.0 MP",
    Bcamera: "5.0MP",
    TP: "G+G multi-touch",
    Band: "No",
    IO: "Docking, Type C, 3.5mm audio jack",
    wifi: "802.11 b/g/n/ac/Ax (wifi6)",
    bluetooth: "5.0",
    Sensore: "G/L",
    GPS: "No",
    Sim: "No",
    TFlash: "upto 256GB",
    battery: "5000 mAh (Non-removable)",
    wifiStatus: "wifi6",

    //Images
    alt: "Pixium T15",
    image: "assets/img/products/d20s.png",

    // tablet Images
    tabletImage1: "assets/img/products/T15/T15-0.jpg",
    tabletImage2: "assets/img/products/T15/T15-1.jpg",
    tabletImage3: "assets/img/products/T15/T15-4.jpg",
    tabletImage4: "assets/img/products/T15/T15-7.png",
    tabletImage5: "assets/img/products/T15/T15-8.png",

    //Flayers
    tabletFlayer1: "assets/img/products/X3/flayers/X3-1.png",
    tabletFlayer2: "assets/img/products/X3/flayers/X3-2.png",
    tabletFlayer3: "assets/img/products/X3/flayers/X3-3.png",
    
    //Features
    bandIcon: "assets/img/products/Specs/5g.svg",
    simIcon: "assets/img/products/Specs/dualSim.svg",
    osIcon: "assets/img/products/Specs/androidOs.svg",
    batteryIcon: "assets/img/products/Specs/battery.svg",
    tfIcon: "assets/img/products/Specs/TF.svg",
    ramIcon: "assets/img/products/Specs/ram.svg",
    romIcon: "assets/img/products/Specs/rom.svg",
    wifiIcon: "assets/img/products/Specs/wifi.svg",
    frontCamIcon: "assets/img/products/Specs/frontCam.svg",
    backCamIcon: "assets/img/products/Specs/backCam.svg",
    chargerIcon: "assets/img/products/Specs/typeC.svg",
    bluetoothIcon: "assets/img/products/Specs/bluetooth.svg",
    gpsIcon:"assets/img/products/Specs/gps.svg",
    cpuIcon: "assets/img/products/Specs/cpu.svg",

  },

  //   T20
  {
    id: 2,
    title: "Pixium T20",
    subtitle: "4G Version",
    Model: "T20",

    //Specs
    platform: "SC9863A Octa Core upto 1.6GHz",
    screen: '10.1" FHD, IPS, 1200*1920',
    Capacity: "ROM 4GB + RAM 64GB / ROM 6GB+ RAM 32GB",
    ram: "4GB/6GB",
    rom: "64GB/32GB",
    os: "Android 12",
    weight: "244*164*9.5 MM",
    Fcamera: "5.0 MP",
    Bcamera: "13MP",
    TP: "G+G multi-touch",
    Band: "B1, B3, B5, B7, B8, B20",
    IO: "Type C, 3.5mm audio jack",
    wifi: "Dual Band 2.5G+5G wifi 802.11 b/g/n/ac",
    bluetooth: "5.0",
    Sensore: "G/P/L",
    GPS: "Yes",
    Sim: "1* Micro SIM",
    TFlash: "upto 256GB",
    battery: "6000 mAh (Non-removable)",
    wifiStatus: "Dual band",

    //Images
    alt: "Pixium T20",
    image: "assets/img/products/d20s.png",

    // tablet Images
    tabletImage1: "assets/img/products/T20/T20-0.jpg",
    tabletImage2: "assets/img/products/T20/T20-1.png",
    tabletImage3: "assets/img/products/T20/T20-2.jpg",
    tabletImage4: "assets/img/products/T20/T20-0.jpg",
    tabletImage5: "assets/img/products/T20/T20-1.png",

    //Flayers
    tabletFlayer1: "assets/img/products/X3/flayers/X3-1.png",
    tabletFlayer2: "assets/img/products/X3/flayers/X3-2.png",
    tabletFlayer3: "assets/img/products/X3/flayers/X3-3.png",
    
    //Features
    bandIcon: "assets/img/products/Specs/5g.svg",
    simIcon: "assets/img/products/Specs/dualSim.svg",
    osIcon: "assets/img/products/Specs/androidOs.svg",
    batteryIcon: "assets/img/products/Specs/battery.svg",
    tfIcon: "assets/img/products/Specs/TF.svg",
    ramIcon: "assets/img/products/Specs/ram.svg",
    romIcon: "assets/img/products/Specs/rom.svg",
    wifiIcon: "assets/img/products/Specs/wifi.svg",
    frontCamIcon: "assets/img/products/Specs/frontCam.svg",
    backCamIcon: "assets/img/products/Specs/backCam.svg",
    chargerIcon: "assets/img/products/Specs/typeC.svg",
    bluetoothIcon: "assets/img/products/Specs/bluetooth.svg",
    gpsIcon:"assets/img/products/Specs/gps.svg",
    cpuIcon: "assets/img/products/Specs/cpu.svg",


  },

  //   T30
  {
    id: 3,
    title: "Pixium T30",
    subtitle: "4G Version + Metal Case",
    Model: "T30",

    //Specs
    platform: "MTK 8788 Octa Core upto 2.0GHz",
    screen: '10.1" HD, IPS, 800*1280',
    ram: "4GB",
    rom: "128GB",
    os: "Android 12",
    weight: "244*164*9.5MM",
    Fcamera: "5.0 MP",
    Bcamera: "13 MP",
    TP: "G+G multi-touch",
    Band: "B1, B3, B5, B7, B8, B20",
    IO: "Docking, Type C, 3.5mm audio jack",
    wifi: "Dual band 2.4G+5G wifi 802.11 b/g/n/ac",
    bluetooth: "5.0",
    Sensore: "G/P/L",
    GPS: "Yes",
    Sim: "1* Micro SIM",
    TFlash: "upto 256GB",
    battery: "6000 mAh (Non-removable)",
    wifiStatus: "Dual band",

    //Images
    alt: "Pixium T30",
    image: "assets/img/products/d20s.png",

    // tablet Images
    tabletImage1: "assets/img/products/T30/T30-0.jpg",
    tabletImage2: "assets/img/products/T30/T30-1.jpg",
    tabletImage3: "assets/img/products/T30/T30-2.jpg",
    tabletImage4: "assets/img/products/T30/T30-3.jpg",
    tabletImage5: "assets/img/products/T30/T30-5.jpg",

    //Flayers
    tabletFlayer1: "assets/img/products/X3/flayers/X3-1.png",
    tabletFlayer2: "assets/img/products/X3/flayers/X3-2.png",
    tabletFlayer3: "assets/img/products/X3/flayers/X3-3.png",
    
    //Features
    bandIcon: "assets/img/products/Specs/5g.svg",
    simIcon: "assets/img/products/Specs/dualSim.svg",
    osIcon: "assets/img/products/Specs/androidOs.svg",
    batteryIcon: "assets/img/products/Specs/battery.svg",
    tfIcon: "assets/img/products/Specs/TF.svg",
    ramIcon: "assets/img/products/Specs/ram.svg",
    romIcon: "assets/img/products/Specs/rom.svg",
    wifiIcon: "assets/img/products/Specs/wifi.svg",
    frontCamIcon: "assets/img/products/Specs/frontCam.svg",
    backCamIcon: "assets/img/products/Specs/backCam.svg",
    chargerIcon: "assets/img/products/Specs/typeC.svg",
    bluetoothIcon: "assets/img/products/Specs/bluetooth.svg",
    gpsIcon:"assets/img/products/Specs/gps.svg",
    cpuIcon: "assets/img/products/Specs/cpu.svg",

  },
];
export default Tablets;
