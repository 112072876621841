import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";
import RelatedProducts from "../Components/RelatedProducts";
//Styling
import "react-fontawesome";
import "./Home.css";
import "../Components/responsive.css";
//SLick Slider
import Slider from "react-slick";
export default function XR1() {
  const hero = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 1100,
    autoplaySpeed: 3000,
    cssEase: "linear",
    nextArrow: (
      <div>
        <div className="next-slick-arrow"> ⟩ </div>
      </div>
    ),
    prevArrow: (
      <div>
        <div className="prev-slick-arrow"> ⟨ </div>
      </div>
    ),
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
          dots: false,
          arrows: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          dots: false,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };
  // THumbnail Slider
  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);
  const [slider1, setSlider1] = useState(null);
  const [slider2, setSlider2] = useState(null);

  useEffect(() => {
    setNav1(slider1);
    setNav2(slider2);
  });
  return (
    <>
      <Navbar />
      <section className="conatiner text-center page-section mb-0 hero">
        <div className="contianer hero slider">
          <br />
          <Slider {...hero}>
            <div>
              <img
                src={"/assets/img/products/banner/product/3.jpg"}
                alt="pixium"
                className="center img-fluid"
              />
            </div>
            <div>
              <img
                src={"/assets/img/products/banner/product/4.jpg"}
                alt="pixium"
                className="center img-fluid"
              />
            </div>
          </Slider>
        </div>
      </section>
      <br />

      <section class="text-center">
        <h2 class="text-muted">PRXIUM Rugged XR1 </h2>

        <div class="container-xxl py-5">
          <div class="container">
            <div class="row g-5">
              <div class="col-sm-12 ">
                <div class="h-100">
                  <img
                    class="img-fluid"
                    src="/assets/img/products/Details/X2/Pixium-X2-7.png"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="container-xxl py-5">
          <div class="container">
            <div class="row g-5">
              <div class="col-md-12 ">
                <div class="h-100">
                  <h1 class="text-muted">
                    A device made for harsh environment
                  </h1>
                  <strong>
                    Gorilla touch panel with IP68 rugged body – work from sea to
                    desert
                  </strong>
                  <img
                    class="card-img rounded-0 img-fluid"
                    src="/assets/img/products/Details/XR1/Pixium-Rugged (15).png"
                  />
                  <img
                    class="card-img rounded-0 img-fluid"
                    src="/assets/img/products/Details/XR1/Pixium-Rugged (12).png"
                  />
                  <img
                    class="card-img rounded-0 img-fluid"
                    src="/assets/img/products/Details/XR1/Pixium-Rugged (14).png"
                  />
                  <img
                    class="card-img rounded-0 img-fluid"
                    src="/assets/img/products/Details/XR1/Pixium-Rugged (13).png"
                  />
                  <img
                    class="card-img rounded-0 img-fluid"
                    src="/assets/img/products/Details/XR1/Pixium-Rugged (11).png"
                  />
                  <img
                    class="card-img rounded-0 img-fluid"
                    src="/assets/img/products/Details/XR1/Pixium-Rugged (10).png"
                  />
                  <p class="mb-4"> </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="container-xxl py-5">
          <div class="container">
            <div class="row g-5">
              <div class="col-md-6">
                <h1 class=" pt-5">
                  {" "}
                  <span style={{ color: "#e93b05;" }}>6000</span>mAh big battery
                  long life working time
                </h1>
              </div>
              <div class="col-md-6  ig">
                <img
                  class="img-fluid"
                  src="/assets/img/products/Details/XR1/Pixium-Rugged (6).png"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>

        <div class="container-xxl py-5">
          <div class="container">
            <div class="row g-5">
              <div class="col-md-6 ">
                <img
                  class="img-fluid"
                  src="/assets/img/products/Details/XR1/Pixium-Rugged (7).png"
                  alt=""
                />
              </div>
              <div class="col-md-6 ">
                <h1 class=" pt-5">
                  18W <span style={{ color: "#e93b05;" }}>high speed</span>{" "}
                  wireless charging
                </h1>
                <p>Reverse wireless charging - use phone as a powerbank</p>
              </div>
            </div>
          </div>
        </div>
        <div class="container-xxl py-5">
          <div class="container">
            <div class="row g-5">
              <div class="col-md-12 ">
                <div class="h-100">
                  <h1 class="text-muted">
                    Developed for a wire of industrial occupations and extreme
                    environments
                  </h1>
                  <img
                    class="card-img rounded-0 img-fluid"
                    src="/assets/img/products/Details/XR1/Pixium-Rugged (4).png"
                  />
                  <img
                    class="card-img rounded-0 img-fluid"
                    src="/assets/img/products/Details/XR1/Pixium-Rugged (9).png"
                  />
                  <img
                    class="card-img rounded-0 img-fluid"
                    src="/assets/img/products/Details/XR1/Pixium-Rugged (8).png"
                  />
                  <img
                    class="card-img rounded-0 img-fluid"
                    src="/assets/img/products/Details/XR1/Pixium-Rugged (16).png"
                  />
                  <p class="mb-4"> </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="container text-center ">
          <br />
          <Link to="/Contact" class="g-5 btn btn-outline-primary btn-lg">
            {" "}
            Where to buy
          </Link>
        </div>
      </section>

      <RelatedProducts />
      <Footer />
    </>
  );
}
