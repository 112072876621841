import React, { useState, useEffect } from "react";
// Components
import Mobiles from "../Components/Mobiles";
import Tablets from "../Components/Tablets";
import BackToTop from "../Components/BackToTop";
import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";
//SLick Slider
import Slider from "react-slick";

//Styling
import "react-fontawesome";
import "./Home.css";
import "../Components/responsive.css";
import { Link } from "react-router-dom";

// import required modules
const Home = () => {
  const hero = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 1100,
    autoplaySpeed: 3000,
    cssEase: "linear",
    nextArrow: (
      <div>
        <div className="next-slick-arrow"> ⟩ </div>
      </div>
    ),
    prevArrow: (
      <div>
        <div className="prev-slick-arrow"> ⟨ </div>
      </div>
    ),
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
          dots: false,
          arrows: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          dots: false,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };

  const [tablets, setTablet] = useState(Tablets);
  const filterTablets = (catItem) => {
    const result = Tablets.filter((curData) => {
      return curData.Model === catItem;
    });
    setTablet(result);
    console.log(catItem);
  };

  //Mobiles
  const [mobile, setMobile] = useState(Mobiles);
  const filterMobile = (catItem) => {
    const result = Mobiles.filter((curData) => {
      return curData.Model === catItem;
    });
    setMobile(result);
    console.log(catItem);
  };

  // Related Products
  const Rproduct = {
    dots: false,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    speed: 800,
    autoplaySpeed: 2000,
    cssEase: "linear",
    nextArrow: (
      <div>
        <div className="next-slick-arrow"> ⟩ </div>
      </div>
    ),
    prevArrow: (
      <div>
        <div className="prev-slick-arrow"> ⟨ </div>
      </div>
    ),
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
          dots: true,
          arrows: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          dots: true,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };

  // THumbnail Slider
  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);
  const [slider1, setSlider1] = useState(null);
  const [slider2, setSlider2] = useState(null);

  useEffect(() => {
    setNav1(slider1);
    setNav2(slider2);
  });

  const settingsMain = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    fade: true,
    asNavFor: ".slider-nav",
  };

  const settingsThumbs = {
    slidesToShow: 3,
    slidesToScroll: 1,
    asNavFor: ".slider-for",
    dots: false,
    centerMode: true,
    swipeToSlide: true,
    focusOnSelect: true,
    centerPadding: "10px",
  };

  return (
    <>
      <Navbar />
      <header id="hero">
        <img
          src="/assets/img/hero-bg.jpeg"
          style={{ width: "100%", height: "auto" }}
          className="img-fluid"
          alt="Pixium Header"
        />
      </header>

      <section className="conatiner text-center page-section mb-0 hero">
        <div className="headling ">
          <p>
            <span className="text-uppercase ">Pixium World</span>'s are made in
            a very agile manner and with passion to deliver valuable smartphone
            experiences for all consumers
          </p>
        </div>
      </section>
      <br />
      {/* <!-- ============== About Section ============== ---> */}
      {/* <section
        className="conatiner text-center page-section  mb-0 about"
        id="about"
      >
        {/* <div className=" container-fluid">
          <div className="container aboutCont">
            <p>
              <span className="text-uppercase">Pixium</span> born from an
              inspiration in 1983, 3D Systems has run on innovation for over 30
              years. Since the invention of stereo lithography by charles hull,
              3D technic has gone through more than three decades of continued
              research and development.
            </p>
            <p>
              The telephones evolution, as it moved from a rotary dial design
              with shared "party" telephone lines to digital, portable and
              eventually cellular phones, to todays smartphones.
            </p>
            <p>
              Pixium presents engineered design solutions for industry
              throughout the world by growing into a global 3D solutions company
              focused on connecting our customers with the expertise and high
              tech manufacturing of unique designed 3D products.
            </p>
          </div>
        </div> 
      </section>*/}
      {/* <!-- ======= End About Section ======= --> */}

      {/* <!-- ======= Mobile Section ======= --> */}
      <section id="products" className="container">
        <div className="row" id="products">
          <div className=" col-md-4 col-sm-12 text-center">
            <div className="card">
              <div
                className="card-image"
                onLoad={() => filterMobile("")}
                onClick={() => filterMobile("D20s")}
              >
                <a href="/#productDetail">
                  <img
                    className="img-fluid"
                    src={"/assets/img/products/D20s.png"}
                    alt="Alternate Text"
                  />
                </a>
              </div>
              <div className="card-body">
                <p>PIXIUM D20S</p>
              </div>
            </div>
          </div>

          <div className="col-md-4 col-sm-12 text-center">
            <div className="card">
              <div className="card-image" onClick={() => filterMobile("M20")}>
                <a href="/#productDetail">
                  <img
                    className="img-fluid"
                    src={"/assets/img/products/M20.png"}
                    alt="Alternate Text"
                  />
                </a>
              </div>
              <div className="card-body">
                <p>PIXIUM M20</p>
              </div>
            </div>
          </div>

          <div className="col-md-4 col-sm-12 text-center">
            <div className="card">
              <div className="card-image" onClick={() => filterMobile("M30")}>
                <a href="/#productDetail">
                  <img
                    className="img-fluid"
                    src={"/assets/img/products/M30.png"}
                    alt="Alternate Text"
                  />
                </a>
              </div>
              <div className="card-body">
                <p>PIXIUM M30</p>
              </div>
            </div>
          </div>

          <div className="col-md-4 col-sm-12 text-center">
            <div className="card">
              <div
                className="card-image"
                onClick={() => filterMobile("Rugged-XR1")}
              >
                <a href="/#productDetail">
                  <img
                    className="img-fluid"
                    src={"/assets/img/products/XR1.png"}
                    alt="Alternate Text"
                  />
                </a>
              </div>
              <div className="card-body">
                <p>PIXIUM Rugged XR1</p>
              </div>
            </div>
          </div>

          <div className="col-md-4 col-sm-12 text-center">
            <div className="card">
              <div className="card-image" onClick={() => filterMobile("X2")}>
                <a href="/#productDetail">
                  <img
                    className="img-fluid"
                    src={"/assets/img/products/X2.png"}
                    alt="Alternate Text"
                  />
                </a>
              </div>
              <div className="card-body">
                <p>PIXIUM X2</p>
              </div>
            </div>
          </div>

          <div className="col-md-4 col-sm-12 text-center">
            <div className="card">
              <div className="card-image" onClick={() => filterMobile("X3")}>
                <a href="/#productDetail">
                  <img
                    className="img-fluid"
                    src={"/assets/img/products/X3.png"}
                    alt="Alternate Text"
                  />
                </a>
              </div>
              <div className="card-body">
                <p>PIXIUM X3</p>
              </div>
            </div>
          </div>

          {/* <div className="col-md-4 col-sm-12 text-center">
            <div className="card">
              <div className="card-image" onClick={() => filterMobile("")}>
                <a href="/#productDetail">
                  <img
                    className="img-fluid"
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/img/products/T15/T15-6.jpg"
                    }
                    alt="Alternate Text"
                  />
                </a>
              </div>
              <div className="card-body">
                <p>PIXIUM T15</p>
              </div>
            </div>
          </div> 

          <div className="col-md-4 col-sm-12 text-center">
            <div className="card">
              <div className="card-image" onClick={() => filterMobile("")}>
                <a href="/#productDetail">
                  <img
                    className="img-fluid"
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/img/products/T20/T20-3.jpg"
                    }
                    alt="Alternate Text"
                  />
                </a>
              </div>
              <div className="card-body">
                <p>PIXIUM T20</p>
              </div>
            </div>
          </div>

          <div className="col-md-4 col-sm-12 text-center">
            <div className="card">
              <div className="card-image" onClick={() => filterMobile("")}>
                <a href="/#productDetail">
                  <img
                    className="img-fluid"
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/img/products/T30/T30-7.jpg"
                    }
                    alt="Alternate Text"
                  />
                </a>
              </div>
              <div className="card-body">
                <p>PIXIUM T30</p>
              </div>
            </div>
          </div>
          */}
        </div>
      </section>
      {/* <!-- End Mobile Section --> */}

      {/* <!-- Mobile Description Section --> */}
      {mobile.map((values) => {
        const {
          // specs
          title,
          Model,
          screen,
          weight,
          battery,
          ram,
          storage,
          Fcamera,
          Bcamera,
          IO,
          Sensore,
          //mobile Images
          mobileImage1,
          mobileImage2,
          mobileImage3,
          mobileImage4,
          mobileImage5,

          //mobile Flayers
          mobileFlayer1,
          mobileFlayer2,
          mobileFlayer3,

          // Features
          network,
          networkTitel,
          sims,
          simsTitle,
          os,
          osTitle,
          cpu,
          cpuTitle,
          size,
          sizeTitle,
          ramS,
          romS,
          wifi,
          wifiTitle,
          scanner,
          scannerTitle,
          water,
          waterTitle,
          frontCam,
          backCam,
          backCamTitle,
          charger,
          chargerTitle,
          finger,
          fingerTitle,
          batteryS,
          //
        } = values;
        return (
          <>
            <section id="productDetail" className="col-md-12 col-sm-12 row">
              <div id="productDetails" className="col-md-6  col-sm-12">
                <Slider
                  className="miniSlider"
                  {...settingsMain}
                  asNavFor={nav2}
                  ref={(slider) => setSlider1(slider)}
                >
                  <div className="sliderImg">
                    <img
                      className="img-fluid"
                      src={mobileImage1}
                      alt="Alternate Text"
                    />
                  </div>
                  <div className="sliderImg">
                    <img
                      className="img-fluid"
                      src={mobileImage2}
                      alt="Alternate Text"
                    />
                  </div>
                  <div className="sliderImg">
                    <img
                      className="img-fluid"
                      src={mobileImage3}
                      alt="Alternate Text"
                    />
                  </div>
                  <div className="sliderImg">
                    <img
                      className="img-fluid"
                      src={mobileImage4}
                      alt="Alternate Text"
                    />
                  </div>
                  <div className="sliderImg">
                    <img
                      className="img-fluid"
                      src={mobileImage5}
                      alt="Alternate Text"
                    />
                  </div>
                </Slider>
                <div>
                  <Slider
                    className="thumbnailImg py-5"
                    {...settingsThumbs}
                    asNavFor={nav1}
                    ref={(slider) => setSlider2(slider)}
                  >
                    <div className="thumbnailImg">
                      <img
                        className="img-fluid"
                        src={mobileImage1}
                        alt="Alternate T  ext"
                      />
                    </div>
                    <div className="thumbnailImg">
                      <img
                        className="img-fluid"
                        src={mobileImage2}
                        alt="Alternate Text"
                      />
                    </div>
                    <div className="thumbnailImg">
                      <img
                        className="img-fluid"
                        src={mobileImage3}
                        alt="Alternate Text"
                      />
                    </div>
                    <div className="thumbnailImg">
                      <img
                        className="img-fluid"
                        src={mobileImage4}
                        alt="Alternate Text"
                      />
                    </div>
                    <div className="thumbnailImg">
                      <img
                        className="img-fluid"
                        src={mobileImage5}
                        alt="Alternate Text"
                      />
                    </div>
                  </Slider>
                </div>
              </div>

              <div className="col-md-6  col-sm-12 row">
                <div className="col-md-6">
                  <div className="container productDesc">
                    <h2 className="text-uppercase">{title}</h2>
                    <ul>
                      <li className="py-2">
                        <b> Screen Size </b>
                        {screen}
                      </li>

                      <li className="py-2">
                        <b>Dim & weight </b>
                        {weight}
                      </li>
                      <li className="py-2">
                        <b>Capacity </b>ROM {storage} + RAM {ram}
                      </li>
                      <li>
                        <b>Battery </b>
                        {battery} (Non-removable)
                      </li>
                      <li className="py-2">
                        <b>Front Camera </b>
                        {Fcamera}
                      </li>
                      <li className="py-2">
                        <b>Back Camera </b>
                        {Bcamera}
                      </li>
                      <li className="py-2">
                        <b>IO </b>
                        {IO}
                      </li>
                      <li className="py-2">
                        <b>Sensors </b>
                        {Sensore}
                      </li>
                    </ul>
                    <Link
                      to={"/Mobiles/" + Model}
                    >
                      
                      See Details&nbsp;
                      <i class="fa-solid fa-arrow-up-right-from-square"></i>
                    </Link>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="iconContainer text-center row  col-md-4 col-6">
                    <img
                      className="btn"
                      style={{ height: "60px", width: "auto" }}
                      alt="Pixium"
                      src={network}
                    />
                    <label className="col-md-12"> {networkTitel}</label>
                  </div>
                  <div className="iconContainer text-center row  col-md-4 col-6">
                    <img
                      className="btn"
                      style={{ height: "60px" }}
                      alt="Pixium"
                      src={sims}
                    />
                    <label className="col-md-12"> {simsTitle} </label>
                  </div>
                  <div className="iconContainer text-center row  col-md-4 col-6">
                    <img
                      className="btn"
                      style={{ height: "60px", width: "auto" }}
                      alt="Pixium"
                      src={os}
                    />
                    <label className="col-md-12"> {osTitle} </label>
                  </div>
                  <div className="iconContainer text-center row  col-md-4 col-6">
                    <img
                      className="btn"
                      style={{ height: "60px", width: "auto" }}
                      alt="Pixium"
                      src={batteryS}
                    />
                    <label className="col-md-12"> {battery} </label>
                  </div>
                  <div className="iconContainer text-center row  col-md-4 col-6">
                    <img
                      className="btn"
                      style={{ height: "60px", width: "auto" }}
                      alt="Pixium"
                      src={size}
                    />
                    <label className="col-md-12"> {sizeTitle} Size</label>
                  </div>
                  <div className="iconContainer text-center row  col-md-4 col-6">
                    <img
                      className="btn"
                      style={{ height: "60px", width: "auto" }}
                      alt="Pixium"
                      src={ramS}
                    />
                    <label className="col-md-12"> {ram} RAM</label>
                  </div>
                  <div className="iconContainer text-center row  col-md-4 col-6">
                    <img
                      className="btn"
                      style={{ height: "60px", width: "auto" }}
                      alt="Pixium"
                      src={romS}
                    />
                    <label className="col-md-12"> {storage} ROM</label>
                  </div>
                  <div className="iconContainer text-center row  col-md-4 col-6">
                    <img
                      className="btn"
                      style={{ height: "60px", width: "auto" }}
                      alt="Pixium"
                      src={wifi}
                    />
                    <label className="col-md-12"> {wifiTitle} </label>
                  </div>
                  <div className="iconContainer text-center row col-md-4 col-6">
                    <img
                      className="btn"
                      style={{ height: "60px", width: "auto" }}
                      alt="Pixium"
                      src={scanner}
                    />
                    <label className="col-md-12"> {scannerTitle} </label>
                  </div>
                  <div className="iconContainer text-center row col-md-4 col-6">
                    <img
                      className="btn"
                      style={{ height: "60px", width: "auto" }}
                      alt="Pixium"
                      src={water}
                    />
                    <label className="col-md-12"> {waterTitle} </label>
                  </div>
                  <div className="iconContainer text-center row col-md-4 col-6">
                    <img
                      className="btn"
                      style={{ height: "60px", width: "auto" }}
                      alt="Pixium"
                      src={frontCam}
                    />
                    <label className="col-md-12"> {Fcamera} </label>
                  </div>
                  <div className="iconContainer text-center row col-md-4 col-6">
                    <img
                      className="btn"
                      style={{ height: "60px", width: "auto" }}
                      alt="Pixium"
                      src={backCam}
                    />
                    <label className="col-md-12"> {backCamTitle} </label>
                  </div>
                  <div className="iconContainer text-center row col-md-4 col-6">
                    <img
                      className="btn"
                      style={{ height: "60px", width: "auto" }}
                      alt="Pixium"
                      src={charger}
                    />
                    <label className="col-md-12"> {chargerTitle} </label>
                  </div>
                  <div className="iconContainer text-center row col-md-4 col-6">
                    <img
                      className="btn"
                      style={{ height: "60px", width: "auto" }}
                      alt="Pixium"
                      src={finger}
                    />
                    <label className="col-md-12"> {fingerTitle} </label>
                  </div>
                  <div className="iconContainer text-center row col-md-4 col-6">
                    <img
                      className="btn"
                      style={{ height: "60px", width: "auto" }}
                      alt="Pixium"
                      src={cpu}
                    />
                    <label className="col-md-12"> {cpuTitle} </label>
                  </div>
                </div>
                <div className="divColor col-md-12">
                </div>
              </div>
            </section>

            <section className="hero py-5">
              <div className="contianer slider">
                <Slider {...hero}>
                  <div>
                    <img src={mobileFlayer1} alt="pixium" className="center" />
                  </div>

                  <div>
                    <img src={mobileFlayer2} alt="pixium" />
                  </div>
                  <div>
                    <img src={mobileFlayer3} alt="pixium" />
                  </div>
                </Slider>
              </div>
            </section>
          </>
        );
      })}
      {/* <!-- End Products Description Section --> */}

      <br />
      {/* //Related products */}
      {/* <section className="hero text-center Rproduct text-uppercase">
        <h1 className="RP-Title">Related Products</h1>
        <div className="contianer slider">
          <Slider {...Rproduct} className="sliders">
            <div>
              <a href="/#productDetail">
                <img
                  src={"/assets/img/products/D20s.png"}
                  title="Pixium D20s"
                  onClick={() => filterMobile("d20")}
                  alt="pixium D20s"
                  className="center img-fluid"
                />
              </a>
            </div>

            <div>
              <a href="/#productDetail">
                <img
                  src={
                    "/assets/img/products/T15/T15-1.jpg"
                  }
                  title="Pixium T15"
                  alt="pixium T15"
                  onClick={() => filterMobile("")}
                  className="center img-fluid"
                />
              </a>
            </div>

            <div>
              <a href="/#productDetail">
                <img
                  src={"/assets/img/products/X3.png"}
                  title="Pixium X3"
                  onClick={() => filterMobile("X3")}
                  alt="pixium X3"
                  className="center img-fluid"
                />
              </a>
            </div>

            <div>
              <a href="/#productDetail">
                <img
                  src={
                    "/assets/img/products/T20/T20-3.jpg"
                  }
                  title="Pixium T20"
                  alt="pixium T20"
                  onClick={() => filterMobile("")}
                  className="center img-fluid"
                />
              </a>
            </div> 

            <div>
              <a href="/#productDetail">
                <img
                  src={"/assets/img/products/X2.png"}
                  title="Pixium X2"
                  onClick={() => filterMobile("X2")}
                  alt="pixium X2"
                  className="center img-fluid"
                />
              </a>
            </div>

            
            <div>
              <a href="/#productDetail">
                <img
                  src={"/assets/img/products/M30.png"}
                  title="Pixium M30"
                  onClick={() => filterMobile("M30")}
                  alt="pixium M30"
                  className="center img-fluid"
                />
              </a>
            </div>

            <div>
              <a href="/#productDetail">
                <img
                  src={
                    "/assets/img/products/T20/T20-3.jpg"
                  }
                  title="Pixium T20"
                  alt="pixium T20"
                  onClick={() => filterMobile("")}
                  className="center img-fluid"
                />
              </a>
            </div>

            <div>
              <a href="/#productDetail">
                <img
                  src={"/assets/img/products/M20.png"}
                  title="Pixium M20"
                  onClick={() => filterMobile("M20")}
                  alt="pixium M20"
                  className="center img-fluid"
                />
              </a>
            </div>
            <div>
              <a href="/#productDetail">
                <img
                  src={
                    "/assets/img/products/T20/T20-3.jpg"
                  }
                  title="Pixium T20"
                  alt="pixium T20"
                  onClick={() => filterMobile("")}
                  className="center img-fluid"
                />
              </a>
            </div>

            <div>
              <a href="/#productDetail">
                <img
                  src={"/assets/img/products/XR1.png"}
                  title="Pixium XR1"
                  onClick={() => filterMobile("Rugged-XR1")}
                  alt="pixium XR1"
                  className="center img-fluid"
                />
              </a>
            </div>

            <div>
              <a href="/#productDetail">
                <img
                  src={
                    "/assets/img/products/T30/T30-1.jpg"
                  }
                  title="Pixium T30"
                  alt="pixium T30"
                  onClick={() => filterMobile("")}
                  className="center img-fluid"
                />
              </a>
            </div>
          </Slider>
        </div>
      </section> */}

      <section
        className="hero text-center Rproduct text-uppercase"
        id="Rproduct"
      >
        <h1 className="RP-Title">
          Related Products (<span style={{ color: "#f9944b" }}>Tablets</span>)
        </h1>
        <div className="contianer slider">
          <Slider {...Rproduct} className="sliders">
            <div className="relatedProducts"
                  onLoad={() => filterTablets("")}
                  >
              <a href="/#Rproduct">
                <img
                  src={"/assets/img/products/T15/T15-0.jpg"}
                  title="Pixium T15"
                  alt="pixium T15"
                  onClick={() => filterTablets("T15")}
                  className="center img-fluid"
                />
                <small className="ImgTitle">T15</small>
              </a>
            </div>

            <div className="relatedProducts">
              <a href="/#Rproduct">
                <img
                  src={"/assets/img/products/T20/T20-1.png"}
                  title="Pixium T20"
                  alt="pixium T20"
                  onClick={() => filterTablets("T20")}
                  className="center img-fluid"
                />
                <small className="ImgTitle">T20</small>
              </a>
            </div>
            <div className="relatedProducts">
              <a href="/#Rproduct">
                <img
                  src={"/assets/img/products/T30/T30-0.jpg"}
                  title="Pixium T30"
                  alt="pixium T30"
                  onClick={() => filterTablets("T30")}
                  className="center img-fluid"
                />
                <small className="ImgTitle">T30</small>
              </a>
            </div>

            <div className="relatedProducts">
              <a href="/#Rproduct">
                <img
                  src={"/assets/img/products/T20/T20-0.jpg"}
                  title="Pixium T20"
                  alt="pixium T20"
                  onClick={() => filterTablets("T20")}
                  className="center img-fluid"
                />
                <small className="ImgTitle">T20</small>
              </a>
            </div>
          </Slider>
        </div>
      </section>
      {tablets.map((values) => {
        const {
          // specs
          title,
          subtitle,
          Model,
          platform,
          screen,
          ram,
          rom,
          weight,
          Fcamera,
          Bcamera,
          TP,
          os,
          Band,
          IO,
          wifi,
          bluetooth,
          Sensore,
          GPS,
          Sim,
          TFlash,
          battery,
          wifiStatus,

          //mobile Images
          tabletImage1,
          tabletImage3,
          tabletImage2,
          tabletImage4,
          tabletImage5,

          //tablet Flayers
          tabletFlayer1,
          tabletFlayer2,
          tabletFlayer3,

          // Features
          simIcon,
          osIcon,
          tfIcon,
          gpsIcon,
          bluetoothIcon,
          ramIcon,
          romIcon,
          wifiIcon,
          frontCamIcon,
          backCamIcon,
          chargerIcon,
          batteryIcon,

          //
        } = values;
        return (
          <section id="productDetail" className="col-md-12 col-sm-12 row">
            <div id="productDetails" className="col-md-6  col-sm-12">
              <Slider
                className="miniSlider"
                {...settingsMain}
                asNavFor={nav2}
                ref={(slider) => setSlider1(slider)}
              >
                <div className="sliderImg">
                  <img
                    className="img-fluid"
                    src={tabletImage1}
                    alt="Alternate Text"
                  />
                </div>
                <div className="sliderImg">
                  <img
                    className="img-fluid"
                    src={tabletImage2}
                    alt="Alternate Text"
                  />
                </div>
                <div className="sliderImg">
                  <img
                    className="img-fluid"
                    src={tabletImage3}
                    alt="Alternate Text"
                  />
                </div>
                <div className="sliderImg">
                  <img
                    className="img-fluid"
                    src={tabletImage4}
                    alt="Alternate Text"
                  />
                </div>
                <div className="sliderImg">
                  <img
                    className="img-fluid"
                    src={tabletImage5}
                    alt="Alternate Text"
                  />
                </div>
              </Slider>
              <div>
                <Slider
                  className="thumbnailImg py-5"
                  {...settingsThumbs}
                  asNavFor={nav1}
                  ref={(slider) => setSlider2(slider)}
                >
                  <div className="thumbnailImg">
                    <img
                      className="img-fluid"
                      src={tabletImage1}
                      alt="Alternate T  ext"
                    />
                  </div>
                  <div className="thumbnailImg">
                    <img
                      className="img-fluid"
                      src={tabletImage2}
                      alt="Alternate Text"
                    />
                  </div>
                  <div className="thumbnailImg">
                    <img
                      className="img-fluid"
                      src={tabletImage3}
                      alt="Alternate Text"
                    />
                  </div>
                  <div className="thumbnailImg">
                    <img
                      className="img-fluid"
                      src={tabletImage4}
                      alt="Alternate Text"
                    />
                  </div>
                  <div className="thumbnailImg">
                    <img
                      className="img-fluid"
                      src={tabletImage5}
                      alt="Alternate Text"
                    />
                  </div>
                </Slider>
              </div>
            </div>

            <div className="col-md-6  col-sm-12 row">
              <div className="col-md-6">
                <div className="container productDesc">
                  <h2 className="text-uppercase">{title}</h2>(
                  <span style={{ color: "#f9944b" }}>{subtitle}</span>)
                  <ul>
                    <li className="py-2">
                      <b> platform </b>
                      {platform}
                    </li>
                    <li className="py-2">
                      <b> Screen Size </b>
                      {screen}
                    </li>
                    <li className="py-2">
                      <b>Dim & weight </b>
                      {weight}
                    </li>
                    <li className="py-2">
                      <b>TP </b>
                      {TP}
                    </li>
                    <li className="py-2">
                      <b>4G Band </b>
                      {Band}
                    </li>
                    <li className="py-2">
                      <b>IO </b>
                      {IO}
                    </li>
                    <li className="py-2">
                      <b>wifi </b>
                      {wifi}
                    </li>
                    <li className="py-2">
                      <b>Sensors </b>
                      {Sensore}
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-6">
                <div
                  className="iconContainer text-center row  col-md-4 col-6"
                  title="Sim"
                >
                  <img
                    className="btn"
                    style={{ height: "60px" }}
                    alt="Pixium"
                    src={simIcon}
                  />
                  <label className="col-md-12"> {Sim}</label>
                </div>
                <div
                  className="iconContainer text-center row  col-md-4 col-6"
                  title="Tablet OS"
                >
                  <img
                    className="btn"
                    style={{ height: "60px", width: "auto" }}
                    alt="Pixium"
                    src={osIcon}
                  />
                  <label className="col-md-12"> {os} </label>
                </div>
                <div
                  className="iconContainer text-center row  col-md-4 col-6"
                  title="TF Support"
                >
                  <img
                    className="btn"
                    style={{ height: "60px", width: "auto" }}
                    alt="Pixium"
                    src={tfIcon}
                  />
                  <label className="col-md-12"> {TFlash} </label>
                </div>
                <div
                  className="iconContainer text-center row  col-md-4 col-6"
                  title="GPS feature"
                >
                  <img
                    className="btn"
                    style={{ height: "60px", width: "auto" }}
                    alt="Pixium"
                    src={gpsIcon}
                  />
                  <label className="col-md-12"> {GPS}</label>
                </div>
                <div
                  className="iconContainer text-center row  col-md-4 col-6"
                  title="Bluetooth feature"
                >
                  <img
                    className="btn"
                    style={{ height: "60px", width: "auto" }}
                    alt="Pixium"
                    src={bluetoothIcon}
                  />
                  <label className="col-md-12"> {bluetooth}</label>
                </div>
                <div
                  className="iconContainer text-center row  col-md-4 col-6"
                  title="RAM"
                >
                  <img
                    className="btn"
                    style={{ height: "60px", width: "auto" }}
                    alt="Pixium"
                    src={ramIcon}
                  />
                  <label className="col-md-12"> {ram}</label>
                </div>
                <div
                  className="iconContainer text-center row  col-md-4 col-6"
                  title="Tablet Storage"
                >
                  <img
                    className="btn"
                    style={{ height: "60px", width: "auto" }}
                    alt="Pixium"
                    src={romIcon}
                  />
                  <label className="col-md-12"> {rom} </label>
                </div>
                <div
                  className="iconContainer text-center row col-md-4 col-6"
                  title="wifi feature"
                >
                  <img
                    className="btn"
                    style={{ height: "60px", width: "auto" }}
                    alt="Pixium"
                    src={wifiIcon}
                  />
                  <label className="col-md-12"> {wifiStatus} </label>
                </div>
                <div
                  className="iconContainer text-center row col-md-4 col-6"
                  title="Front Camera"
                >
                  <img
                    className="btn"
                    style={{ height: "60px", width: "auto" }}
                    alt="Pixium"
                    src={frontCamIcon}
                  />
                  <label className="col-md-12"> {Fcamera} </label>
                </div>
                <div
                  className="iconContainer text-center row col-md-4 col-6"
                  title="Back Camera"
                >
                  <img
                    className="btn"
                    style={{ height: "60px", width: "auto" }}
                    alt="Pixium"
                    src={backCamIcon}
                  />
                  <label className="col-md-12"> {Bcamera} </label>
                </div>
                <div
                  className="iconContainer text-center row col-md-4 col-6"
                  title="Charging point"
                >
                  <img
                    className="btn"
                    style={{ height: "60px", width: "auto" }}
                    alt="Pixium"
                    src={chargerIcon}
                  />
                  <label className="col-md-12"> Type C </label>
                </div>
                <div
                  className="iconContainer text-center row col-md-4 col-6"
                  title="Battery"
                >
                  <img
                    className="btn"
                    style={{ height: "60px", width: "auto" }}
                    alt="Pixium"
                    src={batteryIcon}
                  />
                  <label className="col-md-12"> {battery} </label>
                </div>
              </div>
              <div className="divColor col-md-12">
                {/* <div className="container text-center pt-5">
                  <Link
                    to={"/Tablets/" + Model}
                    className="btn btn-outline-primary"
                  >
                    See Details 
                  </Link>
                </div> */}
              </div>
            </div>
          </section>
        );
      })}
       <section className="conatiner text-center page-section mb-0 hero">
        <div className="contianer hero slider">
          <br />
          <Slider {...hero}>
            <div>
              <img
                src={"/assets/img/products/banner/1.png"}
                alt="pixium"
                className="center img-fluid"
              />
            </div>
            <div>
              <img
                src={"/assets/img/products/T15/T15-7.png"}
                alt="pixium"
                className="center img-fluid"
              />
            </div>
            <div>
              <img
                src={"/assets/img/products/banner/2.png"}
                alt="pixium"
                className="center img-fluid"
              />
            </div>
            <div>
              <img
                src={"/assets/img/products/T15/T15-8.png"}
                alt="pixium"
                className="center img-fluid"
              />
            </div>
            <div>
              <img
                src={"/assets/img/products/banner/3.png"}
                alt="pixium"
                className="center img-fluid"
              />
            </div>
            <div>
              <img
                src={"/assets/img/products/T30/T30-1.jpg"}
                alt="pixium"
                className="center img-fluid"
              />
            </div>
            <div>
              <img
                src={"/assets/img/products/banner/4.png"}
                alt="pixium"
                className="center img-fluid"
              />
            </div>
            <div>
              <img
                src={"/assets/img/products/T30/T30-5.jpg"}
                alt="pixium"
                className="center img-fluid"
              />
            </div>
            <div>
              <img
                src={"/assets/img/products/banner/5.png"}
                alt="pixium"
                className="center img-fluid"
              />
            </div>
            <div>
              <img
                src={"/assets/img/products/T20/T20-1.png"}
                alt="pixium"
                className="center img-fluid"
              />
            </div>
          </Slider>
        </div>
      </section>

      {/* <Products/> */}

      <Footer />
      <BackToTop />
    </>
  );
};

export default Home;
